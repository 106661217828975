import { API } from 'aws-amplify';
import {
	PUSH_NOTIFICATION,
	REMOVE_MESSAGE,
	PUSH_ALL_MESSAGES,
	TOGGLE_VIDEO_PLAYER,
	UPDATE_CONFIG,
	RESET_CONFIG_STATUS,
	SET_CONNECTION_STATUS,
	SET_ERROR
} from './types';
import CONFIGURATION from '../../app_configuration';

export const removeMessage = () => {
	return function (dispatch) {
		dispatch({ type: REMOVE_MESSAGE });
	};
};

export const setError = error => async (dispatch, useState) => {
	const { common } = useState();
	dispatch({
		type: SET_ERROR,
		payload: {
			errors: common.errors ? [...common.errors, error] : [error]
		}
	});
};

export const removeError = index => async (dispatch, useState) => {
	const { common } = useState();
	dispatch({
		type: SET_ERROR,
		payload: {
			errors: common.errors.filter((item, idx) => idx !== index)
		}
	});
};

export const pushMessage = props => async dispatch => {
	dispatch({
		type: PUSH_NOTIFICATION,
		payload: {
			preventDuplicate: props.preventDuplicate,
			message: props.message,
			type: props.type,
			persist: props.persist,
			removePrevious: props.removePrevious,
			action: props.action
		}
	});
};

export const updateConfig = data => async dispatch => {
	let response = {};
	try {
		response = await API.patch(CONFIGURATION.AWS_API_NAME, `/tenant/app-versions`, {
			response: true,
			body: data
		});
	} catch (err) {
		response.status = 500;
	}

	dispatch({
		type: UPDATE_CONFIG,
		payload: response
	});
};

export const resetConfigStatus = () => async dispatch => {
	dispatch({
		type: RESET_CONFIG_STATUS
	});
};

export const setIsOnline = isOnline => async dispatch => {
	dispatch({
		type: SET_CONNECTION_STATUS,
		payload: isOnline
	});
};

export const toggleVideoPlayer = videoSettings => async (dispatch, getState) => {
	const state = getState();
	dispatch({
		type: TOGGLE_VIDEO_PLAYER,
		payload: {
			playerOpen: !state.common.playerOpen,
			...videoSettings
		}
	});
};

export const pushAllMessagesInStore = props => async dispatch => {
	dispatch({
		type: PUSH_ALL_MESSAGES,
		payload: props
	});
};
