import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import CONFIGURATION from '../../app_configuration';

// Define an async thunk action to fetch data
export const updateAgentConfig = createAsyncThunk(
	'agentManager/updateAgentConfig',
	async (data, { rejectWithValue }) => {
		const { id, agentConfig } = data;

		try {
			const response = await API.put(
				CONFIGURATION.AWS_API_NAME,
				`/agents-admin/agent-config/${id}`,
				{
					response: true,
					body: agentConfig
				}
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data.detail);
		}
	}
);

export const getAgentConfig = createAsyncThunk('agentManager/getAgentConfig', async id => {
	const response = await API.get(CONFIGURATION.AWS_API_NAME, `/agents-admin/agent-config/${id}`, {
		response: true
	});
	return response.data;
});

export const getAgentConfigList = createAsyncThunk('agentManager/getAgentConfigList', async () => {
	const response = await API.get(CONFIGURATION.AWS_API_NAME, `/agents-admin/agent-configs`, {
		response: true
	});
	return response.data;
});

// Define a slice to manage the data state
const dataSlice = createSlice({
	name: 'agentManager',
	initialState: {
		data: null,
		loading: false,
		error: null
	},
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getAgentConfigList.pending, state => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getAgentConfigList.fulfilled, (state, action) => {
				state.loading = false;
				state.agentList = action.payload;
			})
			.addCase(getAgentConfigList.rejected, state => {
				state.loading = false;
				state.error = true;
			})
			.addCase(getAgentConfig.pending, state => {
				state.loadingAgent = true;
				state.error = null;
				state.activeAgent = null;
			})
			.addCase(getAgentConfig.fulfilled, (state, action) => {
				state.loadingAgent = false;
				state.activeAgent = action.payload;
			})
			.addCase(getAgentConfig.rejected, state => {
				state.loadingAgent = false;
				state.error = true;
			})
			.addCase(updateAgentConfig.pending, state => {
				state.error = null;
				state.agentUpdated = false;
			})
			.addCase(updateAgentConfig.fulfilled, (state, action) => {
				state.activeAgent = action.payload;
				state.agentUpdated = true;
			})
			.addCase(updateAgentConfig.rejected, (state, action) => {
				state.error = action.payload;
				state.agentUpdated = false;
			});
	}
});

export const { updateContent } = dataSlice.actions;
// Export the reducer
export default dataSlice.reducer;
