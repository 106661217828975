import { API, Auth, Analytics } from 'aws-amplify';
import {
	SETUP_TOTP,
	SETUP_SMS,
	ENTER_VERIFICATION_MFA_CODE,
	SETUP_MFA_INFO,
	AUTH_USER,
	FETCH_MY_CONTACTS,
	PUSH_NOTIFICATION,
	REINVITE_USER,
	DELETE_USER,
	CHANGE_USER_ROLE,
	INVITE_USER_PENDING,
	INVITE_USER_SUCCESS,
	INVITE_USER_FAILURE
} from './types';
import { ANALYTICS_EVENT } from '../../common/ApmlifyRecordEvents';

import CONFIGURATION from '../../app_configuration';
import { setError } from './commonActions';

export const setupTOTP = (user, toggle_value) => {
	return function (dispatch) {
		console.log('from profileScreenActions setupTOTP toggle_value: ', toggle_value);

		if (typeof toggle_value === 'undefined' || toggle_value === null) return;

		if (toggle_value === 'NOMFA' || toggle_value === 'SMS') {
			dispatch({ type: SETUP_MFA_INFO });
			if (toggle_value === 'SMS' && user.attributes.phone_number_verified === false) {
				// dispatch({ type: SETUP_MFA_SMS_ERROR, payload: "In order to use SMS MFA verify your phone number first." });
				dispatch({
					type: PUSH_NOTIFICATION,
					payload: {
						message: 'In order to use SMS MFA verify your phone number first.',
						type: 'error'
					}
				});
			} else {
				Auth.setPreferredMFA(user, toggle_value)
					.then(data => {
						console.log('from profileAcreenActions Auth.setupTOTP : ', data);

						const message =
							toggle_value === 'NOMFA'
								? 'Successfully turned off MFA.'
								: 'Successfully set SMS for your MFA method.';
						// dispatch({ type: SETUP_MFA_INFO, payload: { message: message, type: "info" } });
						dispatch({
							type: PUSH_NOTIFICATION,
							payload: { message, type: 'success' }
						});
						// ...
					})
					.then(() => {
						dispatch({ type: SETUP_SMS });
						if (toggle_value === 'SMS')
							Analytics.record(ANALYTICS_EVENT().MFA_SMS.success);
						else Analytics.record(ANALYTICS_EVENT().MFA_OFF.success);
						updateCurrentAuthUserWithoutCache(dispatch);
					})
					.catch(e => {
						console.log(e);
						Analytics.record(ANALYTICS_EVENT(e.message).MFA_SMS.error);

						const message =
							e.message ===
							'User does not have a phone_number set to turn on SMS MFA.'
								? 'Please fill-in and verify your Phone Number to turn SMS MFA on.'
								: e.message;

						// dispatch({ type: SETUP_MFA_SMS_ERROR, payload: message });
						dispatch({
							type: PUSH_NOTIFICATION,
							payload: { message, type: 'error' }
						});
					});
			}
		} else {
			Auth.setupTOTP(user)
				.then(code => {
					// console.log("from profileAcreenActions Auth.setupTOTP : ", code);
					dispatch({ type: SETUP_TOTP, payload: code });
					updateCurrentAuthUserWithoutCache(dispatch);
					// ...
				})
				.catch(e => {
					// dispatch({ type: SETUP_MFA_ERROR, payload: e.message });
					dispatch({
						type: PUSH_NOTIFICATION,
						payload: { message: e.message, type: 'error' }
					});
				});
		}
	};
};

export const enterVerificationMFACode = input => {
	return function (dispatch) {
		console.log('from profileScreenActions toggle_value: ', input);

		dispatch({ type: ENTER_VERIFICATION_MFA_CODE, payload: input });
	};
};

export const verifyTotpToken = (user, challengeAnswer) => {
	return function (dispatch) {
		console.log('from profileScreenActions toggle_value: ', challengeAnswer);

		Auth.verifyTotpToken(user, challengeAnswer)
			.then(() => {
				// don't forget to set TOTP as the preferred MFA method
				Auth.setPreferredMFA(user, 'TOTP');
				Analytics.record(ANALYTICS_EVENT().MFA_TOTP.success);
				// console.log("from verifyTotpToken: challenge verified and setPreferedMFA set to TOPT");
				dispatch({ type: SETUP_MFA_INFO });
				dispatch({
					type: PUSH_NOTIFICATION,
					payload: {
						message: 'Successfully set TOTP for your MFA.',
						type: 'success'
					}
				});
				// ...
			})
			.catch(e => {
				// Token is not verified
				console.log('from profileScreenActions error: ', e);
				Analytics.record(ANALYTICS_EVENT(e.message).MFA_TOTP.error);
				// dispatch({ type: SETUP_MFA_ERROR, payload: e.message });
				dispatch({
					type: PUSH_NOTIFICATION,
					payload: { message: e.message, type: 'error' }
				});
			});
	};
};

export const updateUserAttr = (user, attributes, phoneChanged) => {
	return async function (dispatch) {
		Auth.updateUserAttributes(user, attributes)
			.then(data => {
				if (data === 'SUCCESS') {
					updateCurrentAuthUserWithoutCache(dispatch);
					if (phoneChanged) {
						setTimeout(() => {
							dispatch(resendSMSVerificationCode());
						}, 1000);
					}
					dispatch({
						type: PUSH_NOTIFICATION,
						payload: {
							message: 'Successfully updated user attributes.',
							type: 'success'
						}
					});
				} else {
					dispatch({
						type: PUSH_NOTIFICATION,
						payload: { message: data.message, type: 'error' }
					});
				}

				// ...
			})
			.catch(e => {
				// dispatch({ type: UPDATE_USER_ATTR_ERROR, payload: e.message });
				dispatch({
					type: PUSH_NOTIFICATION,
					payload: { message: e.message, type: 'error' }
				});
			});
	};
};

export const resendSMSVerificationCode = () => {
	return function (dispatch) {
		// console.log("from profileScreenActions toggle_value: ");

		Auth.verifyCurrentUserAttribute('phone_number')
			.then(() => {
				console.log('a verification code is sent');
				// dispatch({
				//   type: RESEND_SUBMIT_SMS_VERIFICATION_CODE,
				//   payload: { message: "Verification code is sent to your mobile.", type: "info" }
				// });
				dispatch({
					type: PUSH_NOTIFICATION,
					payload: {
						message: 'Verification code is sent to your mobile.',
						type: 'success'
					}
				});
			})
			.catch(e => {
				console.log('ERRRRRRROR');
				// dispatch({
				//   type: RESEND_SUBMIT_SMS_VERIFICATION_CODE,
				//   payload: { message: e.message, type: "error" }
				// });
				dispatch({
					type: PUSH_NOTIFICATION,
					payload: { message: e.message, type: 'error' }
				});
			});
	};
};

export const submitSMSVerificationCode = code => {
	return function (dispatch) {
		// console.log("from profileScreenActions toggle_value: ", attributes);

		Auth.verifyCurrentUserAttributeSubmit('phone_number', code)
			.then(() => {
				// console.log("phone_number verified");
				updateCurrentAuthUserWithoutCache(dispatch);
				// dispatch({
				//   type: RESEND_SUBMIT_SMS_VERIFICATION_CODE,
				//   payload: { message: "Phone number successfully verified.", type: "info" }
				// });
				dispatch({
					type: PUSH_NOTIFICATION,
					payload: {
						message: 'Phone number successfully verified.',
						type: 'success'
					}
				});
			})
			.catch(e => {
				// dispatch({ type: RESEND_SUBMIT_SMS_VERIFICATION_CODE, payload: { message: e.message, type: "error" } });
				dispatch({
					type: PUSH_NOTIFICATION,
					payload: { message: e.message, type: 'error' }
				});
			});
	};
};

const updateCurrentAuthUserWithoutCache = dispatch => {
	Auth.currentAuthenticatedUser({ bypassCache: true }).then(currentAuthUser => {
		// console.log("Updated currentAuthenticatedUser(): ", currentAuthUser);
		dispatch({ type: AUTH_USER, payload: currentAuthUser });
	});
};
export const deleteUser = users => async dispatch => {
	const data = {
		response: true,
		body: users
	};

	try {
		const response = await API.del(CONFIGURATION.AWS_API_NAME, `/users`, data);
		dispatch({
			type: DELETE_USER,
			payload: response
		});
	} catch (err) {
		dispatch(setError({ text: 'There was a problem while trying to delete user' }));
	}
};

export const refreshDeletionStatus = () => async dispatch => {
	dispatch({
		type: DELETE_USER,
		payload: { status: null }
	});
};

export const fetchMyContacts = () => async dispatch => {
	const data = {
		response: true
	};

	let res = {};
	try {
		res = await API.get(CONFIGURATION.AWS_API_NAME, '/users', data);
	} catch (err) {
		res.status = 500;
		dispatch(
			setError({
				text: 'Internal error while loading users — please reload page to try again!'
			})
		);
	}

	dispatch({
		type: FETCH_MY_CONTACTS,
		payload: res
	});
};

export const inviteUser = props => async dispatch => {
	dispatch({
		type: INVITE_USER_PENDING
	});
	const data = {
		body: {
			role: props.invitedUser.userType,
			email: props.invitedUser.email
		},
		response: true
	};
	try {
		await API.post(CONFIGURATION.AWS_API_NAME, `/users/invite`, data);
		dispatch({
			type: INVITE_USER_SUCCESS,
			payload: { message: `User ${props.invitedUser.email} successfully invited` }
		});
	} catch (err) {
		dispatch({
			type: INVITE_USER_FAILURE,
			payload: {
				message:
					err.response.data.detail ??
					'Oops! There was an error while trying to invite user. Please try again later.'
			}
		});
	}
};

export const reinviteUser = userEmail => async dispatch => {
	const action = {
		type: REINVITE_USER,
		payload: { status: 500 }
	};

	try {
		const requestData = {
			body: {
				email: userEmail
			},
			response: true
		};
		const response = await API.put(CONFIGURATION.AWS_API_NAME, `/users/invite`, requestData);
		action.payload.status = response.status;
	} catch (err) {
		console.log(err);
	}

	dispatch(action);
};

export const changeUserRole = (userEmail, newRole) => async dispatch => {
	const action = {
		type: CHANGE_USER_ROLE,
		payload: { status: 500 }
	};
	console.log(userEmail);
	try {
		const requestData = {
			body: {
				property: 'custom:role',
				value: newRole,
				username: userEmail
			},
			response: true
		};
		const response = await API.patch(CONFIGURATION.AWS_API_NAME, `/users/user`, requestData);

		action.payload.status = response.status;
	} catch (err) {
		console.log(err);
	}

	dispatch(action);
};
