export const loginForm = {
	email: {
		presence: { allowEmpty: false, message: 'is required' },
		email: false,
		length: {
			maximum: 64
		}
	},
	password: {
		presence: { allowEmpty: false, message: 'is required' },
		length: {
			minimum: 8,
			maximum: 99
		},
		format: {
			// eslint-disable-next-line
			pattern: /^(?=.*\d)(?=.*[\^$*.\[\]\{\}\(\)\?\-"!@#%&\/\,><':;|_~`])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
			message:
				'should contain at least one number, special characters, uppercase and lowercase letter'
		}
	}
};

export const changePasswordForm = {
	newPassword: {
		presence: { allowEmpty: false, message: 'is required' },
		length: {
			minimum: 8,
			maximum: 99
		},
		format: {
			// eslint-disable-next-line
			pattern: /^(?=.*\d)(?=.*[\^$*.\[\]\{\}\(\)\?\-"!@#%&\/\,><':;|_~`])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
			message:
				'should contain at least one number, special characters, uppercase and lowercase letter'
		}
	},
	newPasswordConfirm: {
		presence: { allowEmpty: false, message: 'is required' },
		length: {
			minimum: 8,
			maximum: 99
		},
		equality: {
			attribute: 'newPassword',
			message: 'Password do not match',
			comparator: (v1, v2) => {
				return v1 === v2;
			}
		},
		format: {
			// eslint-disable-next-line
			pattern: /^(?=.*\d)(?=.*[\^$*.\[\]\{\}\(\)\?\-"!@#%&\/\,><':;|_~`])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
			message:
				'should contain at least one number, special characters, uppercase and lowercase letter'
		}
	}
};
