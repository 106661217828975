/* eslint-disable import/no-cycle */
import { Component } from 'react';
import { Link } from 'react-router-dom';

// Externals
import validate from 'validate.js';
import _ from 'lodash';

// Material helpers
import { withStyles } from '@mui/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Card, Button, IconButton, TextField, Typography, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Auth } from 'aws-amplify';
import { Icon } from '@iconify/react';
import {
	login as loginAction,
	confirmLogin as confirmLoginAction,
	confirmRegistration,
	resendConfirmationCode,
	isLoading as loading,
	setNewPassword,
	resendConfirmationCode as resendCodeAction
} from '../../redux/actions/authActions';
import { AUTH_ERROR_CLEAR } from '../../redux/actions/types';
import { withRouter } from '../../utils/withRouter';
import store from '../../reduxStore';

// Component styles
import styles from './styles';

// Form validation schema
import { loginForm, changePasswordForm } from './schema';
import PopUpMessages from '../../components/PopUpMessages/PopUpMessages';
import { removeMessage } from '../../redux/actions/commonActions';

class SignIn extends Component {
	validateForm = _.debounce(() => {
		const { values, touched } = this.state;

		const newState = { ...this.state };
		const errors = validate(values, touched.newPassword ? changePasswordForm : loginForm);

		newState.errors = errors || {};
		newState.isValid = !errors;

		this.setState(newState);
	}, 300);

	constructor() {
		super();
		this.state = {
			values: {
				email: '',
				password: '',
				newPassword: '',
				newPasswordConfirm: '',
				code: ''
			},
			touched: {
				email: false,
				password: false,
				newPassword: false,
				newPasswordConfirm: false,
				code: false
			},
			errors: {
				email: null,
				password: null,
				newPassword: null,
				newPasswordConfirm: null,
				code: null
			},
			isSubmitting: false,
			isValid: false,
			schema: undefined,
			submitError: null,
			dissableSignUp: false
		};
	}

	componentDidMount() {
		store.dispatch(function (dispatch) {
			// ... which themselves may dispatch many times
			dispatch({ type: AUTH_ERROR_CLEAR });
		});
	}

	componentDidUpdate() {
		if (this.state.isSubmitting && this.props.errorMessage) {
			this.setState({ isSubmitting: false });
		}
	}

	handleBack = () => {
		const { navigate } = this.props;
		navigate(-1);
	};

	handleFieldChange = (field, value) => {
		const newState = { ...this.state };

		newState.submitError = null;
		newState.touched[field] = true;
		newState.values[field] = value;

		this.setState(newState, this.validateForm);
	};

	handleResendVerificationCode = () => {
		const email = {
			cognitoUser: { user: { username: this.state.values.email } }
		};
		this.props.resendCodeAction(email);
	};

	handleSignIn = e => {
		e.preventDefault();
		this.props.loading(true);

		// dispatch clear error message before unmount, sign-in sign-up screen switch
		store.dispatch(function (dispatch) {
			// ... which themselves may dispatch many times
			dispatch({ type: AUTH_ERROR_CLEAR });
		});

		// console.log("this.props.location", this.props.location);

		try {
			const { mfaRequired, cognitoUser, loginRegisterConfirm } = this.props;
			const { values } = this.state;
			const { code } = this.state.values;

			const { email, password } = values;

			// this.setState({ isLoading: true });

			// await signIn(values.email, values.password);
			if (loginRegisterConfirm === true) {
				console.log('loginRegisterConfirm === true');
				this.props.confirmRegistration(
					{ cognitoUser: { user: values.email }, code, email, password },
					this.props.navigate
				);
			} else if (typeof mfaRequired === 'undefined' || mfaRequired === false) {
				// localStorage.setItem("isAuthenticated", true);
				this.props.loginAction(
					values.email,
					values.password,
					this.props.navigate,
					this.props.location
				);
			} else if (
				mfaRequired === true
				// && changePassword == false
			) {
				// we pass in the history function so we can navigate from loginAction
				this.props.confirmLoginAction(
					{ cognitoUser, code },
					this.props.navigate,
					this.props.location
				);
			}
		} catch (error) {
			this.setState({
				// isLoading: false,
				serviceError: error
			});
		}
	};

	handleChangePassword = e => {
		e.preventDefault();
		this.setState({ isSubmitting: true });
		this.props.setNewPassword(
			this.props.cognitoUser,
			this.state.values.newPassword,
			this.props.navigate
		);
	};

	handleSSOClick = async provider => {
		try {
			await Auth.federatedSignIn({ provider });
		} catch (error) {
			console.log('error', error);
		}
	};

	render() {
		const {
			classes,
			mfaRequired,
			resendCode,
			isLoading,
			errorMessage,
			loginRegisterConfirm,
			cognitoUser,
			changePassword,
			message
		} = this.props;
		const { values, touched, errors, isValid } = this.state;

		const showEmailError = touched.email && errors.email;
		const showPasswordError = touched.password && errors.password;

		const showNewPasswordError =
			touched.newPasswordConfirm && errors.newPasswordConfirm
				? errors.newPasswordConfirm[0]
				: false;
		const isLightMode = this.props.themeSettings.themeMode === 'light';

		return (
			<div className={classes.root}>
				<div className={classes.content}>
					<div className={classes.contentHeader}>
						<IconButton className={classes.backButton} onClick={this.handleBack} />
					</div>
					{isLightMode ? (
						<img
							alt="Responsum Logo"
							className={classes.logoImage}
							src="/images/logos/logo-full.png"
						/>
					) : (
						<img
							alt="Responsum Logo"
							className={classes.logoImage}
							src="/images/logos/logo-full-dark-background.png"
						/>
					)}
					<Card sx={{ m: 1.5 }}>
						<div className={classes.contentBody}>
							{!mfaRequired && !loginRegisterConfirm && !changePassword && (
								<form className={classes.form} onSubmit={this.handleSignIn}>
									<Typography className={classes.title} variant="h4">
										Sign in with your email address
									</Typography>
									<br />
									<div className={classes.fields}>
										<TextField
											autoFocus
											data-test="login-mail-input"
											className={classes.textField}
											label="Email address"
											name="email"
											onChange={event =>
												this.handleFieldChange('email', event.target.value)
											}
											type="text"
											value={values.email}
											variant="outlined"
										/>
										{showEmailError && (
											<Typography
												className={classes.fieldError}
												variant="caption"
											>
												{errors.email[0]}
											</Typography>
										)}
										<TextField
											className={classes.textField}
											label="Password"
											data-test="login-password-input"
											name="password"
											onChange={event =>
												this.handleFieldChange(
													'password',
													event.target.value
												)
											}
											type="password"
											value={values.password}
											variant="outlined"
										/>
										{showPasswordError && (
											<Typography
												className={classes.fieldError}
												variant="caption"
												data-test="feedback-message"
											>
												{errors.password[0]}
											</Typography>
										)}
									</div>
									{errorMessage && (
										<Typography
											className={classes.submitError}
											variant="caption"
											data-test="feedback-message"
										>
											{errorMessage}
										</Typography>
									)}

									<LoadingButton
										className={classes.signInButton}
										color="primary"
										disabled={!isValid}
										// onClick={this.handleSignIn}
										size="large"
										variant="contained"
										type="submit"
										data-test="login-button"
										loading={isLoading}
									>
										{isLoading ? 'Signing in' : 'Sign in'}
									</LoadingButton>

									{process.env.REACT_APP_FEDERATED_ID && (
										<>
											<Divider sx={{ mt: 2 }}>
												<Typography color="text.secondary">or</Typography>{' '}
											</Divider>
											<LoadingButton
												startIcon={<Icon icon="logos:microsoft-icon" />}
												variant="outlined"
												fullWidth
												sx={{ mt: 2 }}
												size="large"
												onClick={() =>
													this.handleSSOClick('MicrosoftEntra')
												}
											>
												Sign In With Microsoft
											</LoadingButton>
										</>
									)}
									{this.state.dissableSignUp ? (
										''
									) : (
										<Typography className={classes.signUp} variant="body1">
											Don't have an account?{' '}
											<Link className={classes.signUpUrl} to="/auth/register">
												Sign up
											</Link>
										</Typography>
									)}
									<Typography className={classes.signUp} variant="body1">
										Did you forget your password?{' '}
										<Link
											className={classes.signUpUrl}
											to="/auth/reset-password"
										>
											Reset password
										</Link>
									</Typography>
								</form>
							)}

							{mfaRequired && !loginRegisterConfirm && (
								<form className={classes.form} onSubmit={this.handleSignIn}>
									<Typography className={classes.title} variant="h2">
										MFA
									</Typography>
									<Typography className={classes.subtitle} variant="body1">
										{cognitoUser.challengeName === 'SOFTWARE_TOKEN_MFA'
											? 'Please enter MFA code from your software application'
											: cognitoUser.challengeName === 'SMS_MFA'
											? 'MFA code has been sent on your SMS'
											: 'Please enter MFA code'}
									</Typography>
									<div className={classes.fields}>
										<TextField
											required
											className={classes.textField}
											label="Enter code"
											name="code"
											onChange={event =>
												this.handleFieldChange('code', event.target.value)
											}
											value={values.code}
											variant="outlined"
											autoComplete="off"
										/>
										<Button
											className={classes.signInButton}
											color="primary"
											disabled={!isValid}
											// onClick={this.handleSignIn}
											size="large"
											variant="contained"
											type="submit"
										>
											Verify
										</Button>
										{resendCode && cognitoUser.challengeName === 'SMS_MFA' && (
											<Button
												className={classes.signInButton}
												color="primary"
												// onClick={this.handleSignIn}
												size="large"
												variant="contained"
												type="submit"
											>
												Resend Code
											</Button>
										)}
										{errorMessage && (
											<Typography
												className={classes.submitError}
												variant="body2"
											>
												{errorMessage}
											</Typography>
										)}
									</div>
								</form>
							)}
							{loginRegisterConfirm && (
								<form className={classes.form} onSubmit={this.handleSignIn}>
									<Typography className={classes.title} variant="h4">
										Verify your email
									</Typography>
									<Typography className={classes.subtitle} variant="body1">
										Verification code has been sent to your email
									</Typography>
									<div className={classes.fields}>
										<TextField
											required
											className={classes.textField}
											label="Enter code"
											name="code"
											onChange={event =>
												this.handleFieldChange('code', event.target.value)
											}
											value={values.code}
											variant="outlined"
											autoComplete="off"
										/>

										{errorMessage && (
											<Typography
												className={classes.submitError}
												variant="caption"
											>
												{errorMessage}
											</Typography>
										)}

										{
											<>
												<LoadingButton
													className={classes.signInButton}
													color="primary"
													disabled={!isValid}
													// onClick={this.handleSignUp}
													size="large"
													variant="contained"
													type="submit"
													loading={isLoading}
												>
													{isLoading ? 'Verifying' : 'Verify'}
												</LoadingButton>
												<Typography
													className={classes.signUp}
													variant="body2"
												>
													Didn't get the code?{' '}
													<Button
														className={classes.signUpUrl}
														onClick={this.handleResendVerificationCode}
													>
														Resend
													</Button>
												</Typography>
											</>
										}
									</div>
								</form>
							)}
							{this.props.changePassword && (
								<form className={classes.form} onSubmit={this.handleChangePassword}>
									<Typography className={classes.title} variant="h6">
										Change your password
									</Typography>
									<Typography className={classes.subtitle} variant="body1">
										Use the form below to set a new password for your account
									</Typography>
									<div className={classes.fields}>
										<TextField
											required
											autoFocus
											className={classes.textField}
											label="New Password"
											name="newPassword"
											onChange={event =>
												this.handleFieldChange(
													'newPassword',
													event.target.value
												)
											}
											type="password"
											value={values.newPassword}
											variant="outlined"
										/>
										<TextField
											required
											className={classes.textField}
											label="Confirm New Password"
											name="newPasswordConfirm"
											onChange={event =>
												this.handleFieldChange(
													'newPasswordConfirm',
													event.target.value
												)
											}
											type="password"
											value={values.newPasswordConfirm}
											variant="outlined"
										/>
									</div>
									{showNewPasswordError && (
										<Typography
											className={classes.fieldError}
											variant="caption"
										>
											{errors.newPasswordConfirm[0]}
										</Typography>
									)}
									{errorMessage && (
										<Typography className={classes.submitError} variant="body2">
											{errorMessage}
										</Typography>
									)}
									<LoadingButton
										className={classes.signInButton}
										color="primary"
										disabled={!isValid}
										size="large"
										variant="contained"
										type="submit"
										loading={this.state.isSubmitting}
									>
										Reset Password
									</LoadingButton>
								</form>
							)}
						</div>
					</Card>
				</div>
				<PopUpMessages message={message} removeMessage={this.props.removeMessage} />
			</div>
		);
	}
}

function mapStateToProps(state) {
	console.log('Login.mapStateToProps() state:', state);
	return {
		errorMessage: state.auth.error,
		mfaRequired: state.auth.mfa,
		changePassword: state.auth.changePassword,
		cognitoUser: state.auth.cognitoUser,
		isLoading: state.auth.isLoading,
		loginRegisterConfirm: state.auth.login_register_confirm,
		message: state.common.message,
		themeSettings: state.layout
	};
}

function mapDispatchToProps(dispatch) {
	return {
		...bindActionCreators(
			{
				loginAction,
				confirmLoginAction,
				confirmRegistration,
				resendConfirmationCode,
				loading,
				setNewPassword,
				resendCodeAction,
				removeMessage
			},
			dispatch
		),
		dispatch
	};
}

const SignInConnected = connect(mapStateToProps, mapDispatchToProps)(SignIn);

export default withRouter(withStyles(styles)(SignInConnected));
