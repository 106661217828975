import { alpha } from '@mui/material/styles';

export default theme => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		height: '80px',
		zIndex: theme.zIndex.appBar,
		background: alpha(theme.palette.background.paper, 0.5)
	},
	logoWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '60px',
		flexShrink: 0
	},
	logoLink: {
		fontSize: 0,
		textDecoration: 'none'
	},
	logoImage: {
		cursor: 'pointer',
		width: '150px',
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1)
	},
	nameText: {
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
		maxWidth: '170px',
		color: theme.palette.common.white,
		textDecoration: 'none'
	},
	menuButtonOpen: {
		marginLeft: '0px!important'
	},
	menuButtonClosed: {
		marginLeft: '-20px'
	},
	/* 	icon: {
		color: theme.palette.text.darkBackground,
	}, */
	lastIcon: {
		marginRight: theme.spacing(4),
		color: theme.palette.text.darkBackground
	},
	userContainer: {
		display: 'flex',
		marginLeft: 'auto'
	},
	navbarAvatar: {
		padding: 4
	},
	navbarUsername: {
		textTransform: 'none',
		background: theme.palette.background.default,
		marginLeft: 15,
		color: theme.palette.text.secondary,
		padding: '4px 8px'
	},
	navbarUsernameDropdown: {
		marginRight: theme.spacing(1)
	},
	dropdownMenu: {
		width: '160px',
		paddingTop: 0,
		paddingBottom: 0
	},
	dropdownMenuList: {
		paddingBottom: 0
	},
	dropdownMenuLink: {
		textDecoration: 'none'
	},
	dropdownMenuItem: {
		paddingLeft: theme.spacing(0),
		paddingRight: theme.spacing(0),
		paddingTop: theme.spacing(0),
		paddingBottom: theme.spacing(0),
		height: '40px',
		color: 'gray',
		display: 'flex',
		flexDirection: 'column'
	},
	iconAndText: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		height: '100%',
		justifyContent: 'flex-start',
		alignItems: 'center',
		paddingLeft: theme.spacing(2)
	},
	dropdownMenuText: {
		paddingLeft: theme.spacing(1.5),
		fontSize: '12px',
		lineHeight: '12px',
		textDecoration: 'none'
	},
	hideShowBoxContainer: {
		display: 'flex',
		height: '100%',
		alignItems: 'center'
	},
	dropdownIcon: {
		height: '18px',
		width: '18px'
	},
	activeListItem: {
		textDecoration: 'none',
		fontSize: '30px'
	},
	divider: {
		background: theme.palette.background.mainLight,
		width: '128px'
	},
	userDetailsTopbar: {
		textAlign: 'left',
		marginRight: 5,
		marginLeft: theme.spacing(1),
		'& span': {
			opacity: 0.7,
			display: 'flex',
			marginBottom: '5px'
		}
	}
});
