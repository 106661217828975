import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function MuiBackdrop(theme) {
	const varLow = alpha(theme.palette.grey[900], 0.75);
	const varHigh = alpha(theme.palette.grey[900], 0.75);

	return {
		MuiBackdrop: {
			styleOverrides: {
				root: {
					background: [
						`rgb(22,28,36)`,
						`-moz-linear-gradient(75deg, ${varLow} 0%, ${varHigh} 100%)`,
						`-webkit-linear-gradient(75deg, ${varLow} 0%, ${varHigh} 100%)`,
						`linear-gradient(75deg, ${varLow} 0%, ${varHigh} 100%)`
					],
					backdropFilter: 'blur(5px)',
					'&.MuiBackdrop-invisible': {
						background: 'transparent'
					}
				}
			}
		}
	};
}
