import {
	CHANGE_QUARTAL,
	FETCH_QUARTAL,
	FETCH_CONFIG_SETTINGS,
	FETCH_USER_CONFIG_SETTINGS,
	UPDATE_USER_CONFIG_SETTINGS,
	UPDATE_GLOBAL_CONFIG_SETTINGS,
	RESET_CONFIG_SETTINGS_STATUS
} from '../actions/types';

export default function (state = {}, action) {
	switch (action.type) {
		case CHANGE_QUARTAL:
			return {
				...state,
				...action.payload
			};
		case FETCH_QUARTAL:
			return {
				...state,
				...action.payload
			};
		case FETCH_CONFIG_SETTINGS:
			return {
				...state,
				config: action.payload?.data,
				configStatus: action.payload?.status
			};
		case FETCH_USER_CONFIG_SETTINGS:
			return {
				...state,
				userConfig: action.payload?.data,
				userConfigStatus: action.payload?.status
			};
		case UPDATE_USER_CONFIG_SETTINGS:
			return {
				...state,
				userConfigSaveStatus: action.payload.status,
				userConfigError: action.payload.error
			};
		case UPDATE_GLOBAL_CONFIG_SETTINGS:
			return {
				...state,
				configSaveStatus: action.payload.status,
				configError: action.payload.error
			};
		case RESET_CONFIG_SETTINGS_STATUS:
			return {
				...state,
				configSaveStatus: null,
				configStatus: null,
				userConfigStatus: null,
				userConfigSaveStatus: null
			};
		default:
			return state;
	}
}
