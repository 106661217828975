import React from 'react';
import { NavLink } from 'react-router-dom';

// Externals
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Material helpers
import { withStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

// Material components
import {
	Box,
	Button,
	IconButton,
	Link,
	Menu,
	MenuItem,
	Toolbar,
	Typography,
	Tooltip,
	AppBar
} from '@mui/material';

// Material icons
import {
	Feedback,
	AccountBox as AccountBoxIcon,
	KeyboardArrowDown as DownIcon,
	ExitToApp as ExitToAppIcon,
	ListAlt as ListAltIcon
} from '@mui/icons-material';
import MyAvatar from '../MyAvatar';
import InviteUser from '../InviteUserForm';
import { logout as logoutAction } from '../../../../redux/actions/authActions';
import ModeToggle from './Settings/ModeToggle';

// Component styles
import styles from './styles';

const getUserMail = cognitoUser => {
	return cognitoUser?.attributes?.email?.length > 25
		? `${cognitoUser?.attributes?.email?.slice(0, 25)}...`
		: cognitoUser?.attributes?.email;
};

const RootStyle = styled(AppBar)(({ theme }) => ({
	boxShadow: 'none',
	backdropFilter: 'blur(1px)',
	background: 'transparent',
	WebkitBackdropFilter: 'blur(1px)', // Fix on Mobile
	borderBottom: `1px solid ${theme.palette.divider}`
}));

class Topbar extends React.PureComponent {
	constructor(props) {
		super(props);
		this.onSignOut = this.onSignOut.bind(this);
		this.state = {
			anchorEl: null,
			openInviteDialog: false
		};
	}

	onSignOut() {
		// logout
		this.props.action.logoutAction(this.props.history);
	}

	// dropdown on avatar name
	handleClick = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	// dropdown on avatar name
	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	toggleInviteUser = () => {
		this.setState(prevState => ({
			openInviteDialog: !prevState.openInviteDialog
		}));
	};

	render() {
		const { classes, cognitoUser } = this.props;
		const identities = cognitoUser?.attributes?.identities
			? JSON.parse(cognitoUser?.attributes?.identities)
			: [];

		// dropdown on avatar name
		const { anchorEl } = this.state;
		return (
			<>
				<RootStyle>
					<Toolbar>
						<div className={classes.userContainer}>
							<Box display={{ xs: 'none', sm: 'block' }}>
								<div className={classes.hideShowBoxContainer}>
									<Tooltip title="Report a Bug">
										<IconButton
											onClick={() =>
												(window.location =
													'mailto:support@iolap.com?subject=Responsum Feedback')
											}
											sx={{ marginRight: '10px' }}
											size="small"
										>
											<Feedback fontSize="small" />
										</IconButton>
									</Tooltip>
									<ModeToggle />
								</div>
							</Box>
							<Button
								aria-owns={anchorEl ? 'simple-menu' : undefined}
								aria-haspopup="true"
								onClick={this.handleClick}
								className={classes.navbarUsername}
							>
								<MyAvatar />
								{!this.props.isMobile && (
									<>
										<div className={classes.userDetailsTopbar}>
											{getUserMail(this.props.cognitoUser)}

											{this.props.cognitoUser?.attributes?.[
												'custom:role'
											] && (
												<>
													<br />
													<Box display="flex">
														<Typography variant="caption">
															Role:{' '}
														</Typography>
														<Typography
															sx={{
																ml: 0.3,
																textTransform: 'capitalize'
															}}
															variant="caption"
														>
															{
																this.props.cognitoUser.attributes[
																	'custom:role'
																]
															}{' '}
														</Typography>
													</Box>
												</>
											)}
										</div>
										<DownIcon className={classes.navbarUsernameDropdown} />
									</>
								)}
							</Button>

							<Menu
								anchorEl={anchorEl}
								classes={{
									paper: classes.dropdownMenu,
									list: classes.dropdownMenuList
								}}
								anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
								transformOrigin={{ vertical: 'top', horizontal: 'right' }}
								open={Boolean(anchorEl)}
								onClose={this.handleClose}
							>
								{Boolean(!identities.length) && (
									<NavLink
										to="/dashboard/profile"
										className={classes.dropdownMenuLink}
									>
										<MenuItem
											onClick={this.handleClose}
											className={classes.dropdownMenuItem}
										>
											<div className={classes.iconAndText}>
												<AccountBoxIcon className={classes.dropdownIcon} />
												<Typography className={classes.dropdownMenuText}>
													Profile
												</Typography>
											</div>
										</MenuItem>
									</NavLink>
								)}

								<Link
									href="https://responsum.ai/privacy-policy/"
									target="_blank"
									rel="noopener noreferrer"
									onClick={this.preventDefault}
									underline="none"
									className={classes.dropdownMenuLink}
								>
									<MenuItem
										onClick={this.handleClose}
										className={classes.dropdownMenuItem}
									>
										<div
											style={{ textDecoration: 'none', color: 'inherit' }}
											className={classes.iconAndText}
										>
											<ListAltIcon className={classes.dropdownIcon} />
											<Typography className={classes.dropdownMenuText}>
												Privacy Policy
											</Typography>
										</div>
									</MenuItem>
								</Link>
								<MenuItem
									onClick={this.onSignOut}
									classes={{ root: classes.dropdownMenuItem }}
								>
									<div className={classes.iconAndText}>
										<ExitToAppIcon className={classes.dropdownIcon} />
										<Typography className={classes.dropdownMenuText}>
											Logout
										</Typography>
									</div>
								</MenuItem>
							</Menu>
						</div>
					</Toolbar>
				</RootStyle>
				<InviteUser
					openDialog={this.state.openInviteDialog}
					toggleRightSidebar={this.toggleInviteUser}
				/>
			</>
		);
	}
}

function mapStateToProps(state) {
	return {
		cognitoUser: state.auth.cognitoUser
	};
}

function mapDispatchToProps(dispatch) {
	return {
		action: bindActionCreators({ logoutAction }, dispatch)
	};
}

Topbar.defaultProps = {
	onToggleSidebar: () => {}
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Topbar));
