import _ from 'lodash';
import {
	WIZARD_GO_TO_CONNECTORS,
	WIZARD_GO_TO_NEW_CONNECTOR_FORM,
	WIZARD_CONNECT_COLLABORATION_TOOL,
	WIZARD_CREATE_CONNECTOR,
	WIZARD_UPDATE_PROFILE,
	GET_FEATURED_CONNECTORS,
	TEST_CONNECTION,
	CLEAR_TESTED_CONNECTION,
	CLEAR_FEATURED_CONNECTORS,
	CLEAR_CREATED_CONNECTION,
	WIZARD_GO_TO_FINAL_SCREEN,
	CLEAR_ALL_WIZARD_STATE,
	WIZARD_CHOOSE_STEP,
	FETCH_APP_TYPES
} from '../actions/types';

export default (state = {}, action) => {
	switch (action.type) {
		case WIZARD_GO_TO_CONNECTORS:
			return _.omit({ ...state, wizardStep: action.payload.step }, 'connectorType');
		case FETCH_APP_TYPES:
			return {
				...state,
				appTypes: action.payload
			};
		case WIZARD_UPDATE_PROFILE:
			return {
				...state,
				wizardStep: action.payload.wizardStep,
				wizardUser: action.payload.newUser,
				userUpdateStatus: action.payload.status,
				userUpdateErrorMessage: action.payload.errorMessage
			};
		case GET_FEATURED_CONNECTORS:
			return {
				...state,
				wizardStep: action.payload.step,
				featuredConnectors: action.payload.featuredConnectors,
				featuredConnectorsStatus: action.payload.status
			};
		case WIZARD_GO_TO_NEW_CONNECTOR_FORM:
			return {
				...state,
				wizardStep: action.payload.step,
				connectorType: action.payload.connectorType,
				connectorTemplate: action.payload.connectorTemplate
			};
		case WIZARD_CONNECT_COLLABORATION_TOOL:
			return {
				...state,
				oauthStatus: action.payload
			};
		case WIZARD_CHOOSE_STEP:
			console.log('change step', state);
			return {
				...state,
				wizardStep: action.payload.step
			};
		case WIZARD_CREATE_CONNECTOR:
			return {
				...state,
				connectorType: action.payload.connectorType,
				createConnectionStatus: action.payload.status,
				oauthRedirectUrl: action.payload.oauthRedirectUrl
			};
		case TEST_CONNECTION:
			return {
				...state,
				wizardStep: action.payload.step,
				connectionBeingTested: action.payload.connectionBeingTested,
				connectionBeingTestedStatus: action.payload.status
			};
		case CLEAR_TESTED_CONNECTION:
			return _.omit(state, ['connectionBeingTested', 'connectionBeingTestedStatus']);
		case CLEAR_FEATURED_CONNECTORS:
			return _.omit(state, ['featuredConnectors', 'featuredConnectorsStatus']);
		case CLEAR_CREATED_CONNECTION:
			return _.omit(state, ['createConnectionStatus', 'oauthRedirectUrl', 'connectorType']);
		case WIZARD_GO_TO_FINAL_SCREEN:
			return { ...state, wizardStep: 'connector-created-info' };
		case CLEAR_ALL_WIZARD_STATE:
			return {};
		default:
			return state;
	}
};
