// Icons for sidebar links definition
import CONFIGURATION from '../app_configuration';

// DEFINE ALL CONSTANTS FOR PROJECT HERE. IF NEEDED CRETE NEW FILE IN CONSTANTS FOLDER
export const INTERVALS = ['Day', 'Week', 'Month', 'Year'];
export const NAME = 'Responsum';
export const FAQ_CMS_REDUX_QUESTION_FORM_NAME = 'faqCmsQuestionForm';
export const ADMIN_ROLE = 'admin';
export const SUPERADMIN_ROLE = 'superadmin';
export const USER_ROLE = 'user';
export const LOADER_PAYLOAD = 'show_loader';

export const CLASSIFICATION_MODE = 'classification';
export const BASIC_MODE = 'basic';
export const STATUS_INDEXING = 'in_progress';
export const STATUS_SCHEDULED = 'scheduled';
export const STATUS_FAILED = 'failed';
export const STATUS_READY = 'done';
export const STATUS_ERROR = 'error';
export const ONEDRIVE_CONNECTION = 'MicrosoftOnline';
export const UPLOAD_SOURCE = 'Upload';

export const CONVERSATIONAL_TYPE = 'conversational_knowledgebase';
export const SPREADSHEET = 'spreadsheet';
export const FILE = 'file';
export const URL = 'URL';
export const ONEDRIVE = 'onedrive';
export const ACCEPTED_TYPES = {
	file: {
		'application/pdf': ['.pdf'],
		'text/plain': ['.txt'],
		'application/msword': ['.doc'],
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
		'application/vnd.ms-powerpoint': ['.ppt'],
		'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
		'application/rtf': ['.rtf']
	},
	spreadsheet: {
		'text/csv': ['.csv']
	}
};
export const COMMON_ERROR_MESSAGE = 'Failed to fetch needed resources. Please try again later.';

// for link to appear inside sidebar insert the definition here
// for adding link icon please insert icon inside sidebarIcons.js file located in current folder

export const RIGHT_SIDEBAR_WIDTH = '390px';
export const LEFT_SIDEBAR_WIDTH = '210px';
export const APP_BAR_MOBILE = 55;
export const APP_BAR_DESKTOP = 55;

export const S3_BUCKET = {
	bucket: `nola-documents-${process.env.REACT_APP_ENVIRONMENT}`, // REQUIRED -  Amazon S3 bucket
	region: CONFIGURATION.AWS_REGION, // OPTIONAL -  Amazon service region
	identityPoolId: CONFIGURATION.AWS_COGNITO_IDENTITY_POOL_ID
};
