import {
	TOGGLE_CHATBOT_RIGHT_SIDEBAR,
	CHATBOT_SESSION_ID,
	FETCH_PROMPT,
	BOT_MESSAGE_LOADING,
	SAVE_CHAT_HISTORY,
	HISTORY_LOADING,
	SET_CHAT_SESSION,
	SET_IS_STREAMING,
	SET_AGENT_LIST,
	SET_SOURCE_DOCUMENT_DETAILS
} from '../actions/types';

export default function (state = {}, action) {
	switch (action.type) {
		case TOGGLE_CHATBOT_RIGHT_SIDEBAR: {
			return {
				...state,
				rightSidebarOpen: Boolean(action.payload.data),
				data: action.payload.data
			};
		}
		case SET_SOURCE_DOCUMENT_DETAILS: {
			return {
				...state,
				sourceDocumentDetails: action.payload.details
			};
		}
		case CHATBOT_SESSION_ID: {
			return {
				...state,
				...action.payload
			};
		}
		case FETCH_PROMPT: {
			return {
				...state,
				prompt: action.payload.prompt
			};
		}
		case BOT_MESSAGE_LOADING: {
			return {
				...state,
				isLoading: action.payload
			};
		}
		case SAVE_CHAT_HISTORY: {
			return {
				...state,
				history: action.payload,
				initialized: true
			};
		}
		case HISTORY_LOADING: {
			return {
				...state,
				isLoadingHistory: action.payload
			};
		}
		case SET_CHAT_SESSION: {
			return {
				...state,
				sessionId: action.payload
			};
		}

		case SET_IS_STREAMING: {
			return {
				...state,
				isStreaming: action.payload
			};
		}
		case SET_AGENT_LIST: {
			return {
				...state,
				agents: action.payload
			};
		}
		default:
			return state;
	}
}
