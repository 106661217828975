import _ from 'lodash';
import {
	FETCH_CONNECTIONS,
	FETCH_CONNECTION,
	ENABLE_CONNECTION,
	SET_CLICKED_CONNECTOR,
	SET_LATEST_OAUTH_REDIRECT_LINK,
	DISABLE_CONNECTOR,
	CLEAR_DISABLE_CONNECTOR_RESPONSE,
	CLEAR_CONNECTIONS
} from '../actions/types';

export default function (state = {}, action) {
	switch (action.type) {
		case FETCH_CONNECTIONS:
			return {
				...state,
				...action.payload
			};
		case FETCH_CONNECTION:
			return {
				...state,
				connection: action.payload
			};
		case CLEAR_CONNECTIONS:
			return {
				...state,
				status: undefined
			};
		case ENABLE_CONNECTION:
			return {
				...state,
				oauthStatus: action.payload
			};
		case SET_CLICKED_CONNECTOR:
			return {
				...state,
				selectedConnection: action.payload
			};
		case SET_LATEST_OAUTH_REDIRECT_LINK:
			return {
				...state,
				latestOAuthRedirectLink: {
					createConnectionStatus: action.payload.status,
					oauthRedirectUrl: action.payload.oauthRedirectUrl,
					connectionId: action.payload.connectionId
				}
			};
		case DISABLE_CONNECTOR:
			return {
				...state,
				disableConnectorResponse: action.payload.response
			};
		case CLEAR_DISABLE_CONNECTOR_RESPONSE:
			return _.omit(state, ['disableConnectorResponse']);
		default:
			return state;
	}
}
