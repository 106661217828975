import { API } from 'aws-amplify';
import CONFIGURATION from '../../app_configuration';
import {
	TOGGLE_CHATBOT_RIGHT_SIDEBAR,
	CHATBOT_SESSION_ID,
	UPDATE_PROMPT,
	FETCH_PROMPT,
	BOT_MESSAGE_LOADING,
	SAVE_CHAT_HISTORY,
	HISTORY_LOADING,
	SET_CHAT_SESSION,
	SET_IS_STREAMING,
	SET_AGENT_LIST,
	SET_SOURCE_DOCUMENT_DETAILS
} from './types';

export const toggleChatbotRightSidebar = data => dispatch => {
	dispatch({
		type: TOGGLE_CHATBOT_RIGHT_SIDEBAR,
		payload: {
			data
		}
	});
};

export const setSourceDocumentDetails = details => dispatch => {
	dispatch({
		type: SET_SOURCE_DOCUMENT_DETAILS,
		payload: {
			details
		}
	});
};

export const setChatbotConnected = isConnected => dispatch => {
	const payload = isConnected ? { isConnected } : { isConnected, initialized: false };
	dispatch({
		type: CHATBOT_SESSION_ID,
		payload: { ...payload }
	});
};

export const getDocumentDetails = number => dispatch => {
	dispatch({
		type: SET_SOURCE_DOCUMENT_DETAILS,
		payload: {
			number
		}
	});
};

export const fetchPrompt = () => async (dispatch, getState) => {
	let response = null;
	const state = getState();
	const payload = {
		token: state.auth.cognitoUser.signInUserSession.accessToken.jwtToken,
		prompt_name: 'question_prompt'
	};

	try {
		response = await API.post(CONFIGURATION.AWS_API_NAME, '/get_prompt', payload);
	} catch (err) {
		console.log(err);
	}

	dispatch({
		type: FETCH_PROMPT,
		payload: {
			response
		}
	});
};

export const updatePrompt = editedText => async dispatch => {
	let configResponse = null;
	const requestData = {
		response: true,
		body: editedText
	};

	try {
		configResponse = await API.put(CONFIGURATION.AWS_API_NAME, '/chatbot/prompt', requestData);
	} catch (err) {
		configResponse = err.response ?? { status: 500 };
	}

	dispatch({
		type: UPDATE_PROMPT,
		payload: configResponse
	});
};

export const getAgentList = () => async dispatch => {
	let response = null;

	try {
		response = await API.get(CONFIGURATION.AWS_API_NAME, '/agents/agents', { response: true });
	} catch (err) {
		response = err.response ?? { status: 500 };
	}

	dispatch({
		type: SET_AGENT_LIST,
		payload: response
	});
};
export const updateMessageWithToken = (event, isFinal) => async (dispatch, getState) => {
	const state = getState();
	const agentId = event.agent_id;
	const messageHistory = state.chatbot?.history;
	const currentMessages = messageHistory?.[agentId]?.messages;

	if (!isFinal && !state.chatbot.isStreaming) {
		dispatch(setIsStreaming(true));
	}

	if (currentMessages?.length) {
		const targetId = isFinal ? event.updated_item?.timestamp : event.message_id;
		const messageToUpdateIndex = currentMessages.findIndex(
			message => message.timestamp === targetId
		);
		const updatedMessage = {
			...currentMessages[messageToUpdateIndex],
			payload: isFinal ? event.updated_item?.payload : JSON.stringify(event.message)
		};

		const updatedMessages = [...currentMessages];
		updatedMessages.splice(messageToUpdateIndex, 1, updatedMessage);

		dispatch(
			saveChatHistory({
				...messageHistory,
				[agentId]: {
					...messageHistory?.[agentId],
					messages: updatedMessages
				}
			})
		);

		if (isFinal) {
			dispatch(setIsStreaming(false));
			dispatch(setBotMessageLoading(false));
		}
	}
};

export const updateHistory = snapshot => async (dispatch, getState) => {
	const state = getState();
	const agentId = snapshot.agent_id;
	const messageHistory = state.chatbot?.history;
	let newList = snapshot.messages ?? snapshot.history_snapshot.messages;
	if (messageHistory?.[agentId]?.messages?.length) {
		newList = filterAndSortMessages([...newList], agentId, messageHistory);
	}
	dispatch(setHistoryLoading(false));
	dispatch(setBotMessageLoading(false));
	dispatch(
		saveChatHistory({
			...messageHistory,
			[agentId]: {
				...snapshot,
				messages: newList
			}
		})
	);
};

export const setBotMessageLoading = isLoading => async dispatch => {
	dispatch({
		type: BOT_MESSAGE_LOADING,
		payload: isLoading
	});
};

export const setHistoryLoading = isLoading => async dispatch => {
	dispatch({
		type: HISTORY_LOADING,
		payload: isLoading
	});
};

export const saveChatHistory = history => async dispatch => {
	dispatch({
		type: SAVE_CHAT_HISTORY,
		payload: history
	});
};

export const setChatSessionId = sessionId => dispatch => {
	dispatch({
		type: SET_CHAT_SESSION,
		payload: sessionId
	});
};

export const setIsStreaming = isStreaming => dispatch => {
	dispatch({
		type: SET_IS_STREAMING,
		payload: isStreaming
	});
};

const filterAndSortMessages = (upcomingMessages, agentId, messageHistory) => {
	const currentMessages = messageHistory?.[agentId]?.messages ?? [];
	return [...upcomingMessages, ...currentMessages]
		.sort((a, b) => a.index - b.index)
		.filter((v, i, x) => x.findIndex(v2 => v2.index === v.index) === i)
		.filter(message => message.index || message.index === 0);
};
