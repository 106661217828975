import _ from 'lodash';
import {
	ENTER_VERIFICATION_MFA_CODE,
	SETUP_TOTP,
	SETUP_SMS,
	SETUP_MFA_INFO,
	FETCH_MY_CONTACTS,
	REINVITE_USER,
	CHANGE_USER_ROLE,
	DELETE_USER,
	INVITE_USER_PENDING,
	INVITE_USER_FAILURE,
	INVITE_USER_SUCCESS
} from '../actions/types';

export default function (state = {}, action) {
	switch (action.type) {
		case SETUP_TOTP: {
			if (typeof state.code !== 'undefined') {
				return state;
			}
			const returnProps = { ...state, code: action.payload };

			return returnProps;
		}
		case SETUP_SMS: {
			const returnProps = _.omit({ ...state }, ['code']);

			console.log('profile_reducer. action: ', action);
			console.log('profile_reducer. returning props: ', returnProps);
			return returnProps;
		}
		// case SETUP_MFA_SMS_ERROR: {
		//   const returnProps = _.omit({ ...state, message: { type: "error", content: action.payload } }, ["code"]);
		//
		//   console.log("profile_reducer. action: ", action);
		//   console.log("profile_reducer. returning props: ", returnProps);
		//   return returnProps;
		// }
		case ENTER_VERIFICATION_MFA_CODE: {
			const returnProps = { ...state, verificationCode: action.payload };

			console.log('profile_reducer. action: ', action);
			console.log('profile_reducer. returning props: ', returnProps);
			return returnProps;
		}
		// case SETUP_MFA_ERROR: {
		//   const returnProps = { ...state, message: { type: "error", content: action.payload });
		//
		//   console.log("profile_reducer. action: ", action);
		//   console.log("profile_reducer. returning props: ", returnProps);
		//   return returnProps;
		// }
		case SETUP_MFA_INFO: {
			const returnProps = _.omit({ ...state }, ['code']);

			console.log('profile_reducer. action: ', action);
			console.log('profile_reducer. returning props: ', returnProps);
			return returnProps;
		}
		case INVITE_USER_PENDING: {
			return { ...state, error: null, userInvited: false, message: null };
		}
		case INVITE_USER_SUCCESS: {
			return { ...state, error: null, userInvited: true, message: action.payload.message };
		}
		case INVITE_USER_FAILURE: {
			return { ...state, error: action.payload.message, userInvited: false };
		}
		// case UPDATE_USER_ATTR: {
		//   const returnProps = { ...state, message: { type: "info", content: action.payload } };
		//
		//   console.log("profile_reducer. action: ", action);
		//   console.log("profile_reducer. returning props: ", returnProps);
		//   return returnProps;
		// }
		// case REMOVE_MESSAGE: {
		//   const returnProps = { ...state, message: undefined };
		//
		//   console.log("profile_reducer. action: ", action);
		//   console.log("profile_reducer. returning props: ", returnProps);
		//   return returnProps;
		// }
		// case RESEND_SUBMIT_SMS_VERIFICATION_CODE: {
		//   const returnProps = { ...state, message: { type: action.payload.type, content: action.payload.message } };
		//
		//   console.log("profile_reducer. action: ", action);
		//   console.log("profile_reducer. returning props: ", returnProps);
		//   return returnProps;
		// }
		case FETCH_MY_CONTACTS: {
			const returnProps = {
				...state,
				myUsers: action.payload?.data?.users,
				code: action.payload.status,
				nextToken: action.payload?.data?.next_token
			};
			return returnProps;
		}
		case REINVITE_USER: {
			return {
				...state,
				reinviteUserApiResponse: action.payload.status
			};
		}
		case DELETE_USER: {
			return {
				...state,
				status: action.payload.status
			};
		}
		case CHANGE_USER_ROLE:
			return {
				...state,
				changeUserRoleApiResponse: action.payload.status
			};
		default:
			return state;
	}
}
