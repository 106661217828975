import {
	AUTH_USER,
	AUTH_MFA,
	AUTH_NEW_PASSWORD_REQUIRED,
	UNAUTH_USER,
	REGISTER_USER,
	REGISTER_USER_CONFIRM,
	REGISTER_MFA,
	REGISTER_USER_ERROR,
	FORGOT_PASSWORD,
	FORGOT_PASSWORD_CONFIRM,
	AUTH_ERROR,
	AUTH_ERROR_CLEAR,
	IS_LOADING,
	LOGIN_REGISTER_CONFIRMATION,
	GET_USER,
	TRIGGER_PIPELINE
} from '../actions/types';

export default function (state = {}, action) {
	switch (action.type) {
		case TRIGGER_PIPELINE: {
			const returnProps = {
				...state,
				triggerResponse: action.payload
			};
			// console.log("auth_reducer. action: ", action);
			// console.log("auth_reducer. returning props: ", returnProps);
			return returnProps;
		}
		case AUTH_USER: {
			const returnProps = {
				...state,
				error: '',
				authenticated: true,
				initialized: true,
				mfa: false,
				cognitoUser: action.payload,
				changePassword: false
			};
			// console.log("auth_reducer. action: ", action);
			// console.log("auth_reducer. returning props: ", returnProps);
			return returnProps;
		}
		case AUTH_MFA: {
			const returnProps = {
				...state,
				error: '',
				authenticated: false,
				mfa: true,
				changePassword: false,
				cognitoUser: action.payload
			};

			// console.log("auth_reducer. action: ", action);
			// console.log("auth_reducer. returning props: ", returnProps);
			return returnProps;
		}
		case AUTH_NEW_PASSWORD_REQUIRED: {
			const returnProps = {
				...state,
				error: '',
				authenticated: false,
				mfa: false,
				changePassword: true,
				cognitoUser: action.payload
			};

			// console.log("auth_reducer. action: ", action);
			// console.log("auth_reducer. returning props: ", returnProps);
			return returnProps;
		}
		case UNAUTH_USER: {
			const returnProps = {
				...state,
				error: '',
				authenticated: false,
				initialized: true,
				isLoading: false
			};

			// console.log("auth_reducer. action: ", action);
			// console.log("auth_reducer. returning props: ", returnProps);
			return returnProps;
		}
		case REGISTER_USER: {
			const returnProps = {
				...state,
				error: '',
				authenticated: false,
				register: true
			};

			// console.log("auth_reducer. action: ", action);
			// console.log("auth_reducer. returning props: ", returnProps);
			return returnProps;
		}
		case GET_USER: {
			const returnProps = {
				...state,
				permissions: action.payload?.data?.permissions,
				permissionsStatus: action.payload?.status
			};
			return returnProps;
		}
		case REGISTER_USER_CONFIRM: {
			const returnProps = {
				...state,
				error: '',
				authenticated: false,
				register: true,
				mfa_register: false,
				resendCode: false,
				isLoading: false,
				login_register_confirm: false
			};

			// console.log("auth_reducer. action: ", action);
			// console.log("auth_reducer. returning props: ", returnProps);
			return returnProps;
		}
		case REGISTER_MFA: {
			const returnProps = {
				...state,
				error: '',
				mfa_register: true,
				resendCode: false,
				cognitoUser: action.payload
			};

			// ("auth_reducer. action: ", action);
			// console.log("auth_reducer. returning props: ", returnProps);
			return returnProps;
		}
		case REGISTER_USER_ERROR: {
			const returnProps = {
				...state,
				error: action.payload,
				mfa_register: true,
				resendCode: true,
				cognitoUser: action.cognitoUser,
				isLoading: false
			};

			// console.log("auth_reducer. action: ", action);
			// console.log("auth_reducer. returning props: ", returnProps);
			return returnProps;
		}
		case AUTH_ERROR: {
			const returnProps = {
				...state,
				error: action.payload,
				authenticated: false,
				isLoading: false
			};

			// console.log("auth_reducer. action: ", action);
			// console.log("auth_reducer. returning props: ", returnProps);
			return returnProps;
		}
		case FORGOT_PASSWORD: {
			const returnProps = {
				...state,
				error: '',
				forgotPasswordCode: true,
				authenticated: false
			};

			// console.log("auth_reducer. action: ", action);
			// console.log("auth_reducer. returning props: ", returnProps);
			return returnProps;
		}
		case FORGOT_PASSWORD_CONFIRM: {
			const returnProps = {
				...state,
				error: '',
				forgotPasswordCode: false,
				authenticated: false,
				isLoading: false
			};

			// console.log("auth_reducer. action: ", action);
			// console.log("auth_reducer. returning props: ", returnProps);
			return returnProps;
		}
		case AUTH_ERROR_CLEAR: {
			if (state.error !== '') {
				const returnProps = { ...state, error: '', authenticated: false };

				// console.log("auth_reducer. action: ", action);
				// console.log("auth_reducer. returning props: ", returnProps);
				return returnProps;
			}
			return state;
		}
		case IS_LOADING: {
			const returnProps = {
				...state,
				error: '',
				authenticated: false,
				isLoading: action.payload
			};

			// console.log("auth_reducer. action: ", action);
			// console.log("auth_reducer. returning props: ", returnProps);
			return returnProps;
		}
		case LOGIN_REGISTER_CONFIRMATION: {
			const returnProps = {
				...state,
				error: '',
				authenticated: false,
				isLoading: false,
				login_register_confirm: true
			};
			// console.log("auth_reducer. action: ", action);
			// console.log("auth_reducer. returning props: ", returnProps);
			return returnProps;
		}

		default:
			return state;
	}
}
