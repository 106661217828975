import {
	GET_QUESTIONS_TO_REVIEW,
	GET_QUESTIONS_BY_USER,
	ACCEPT_QUESTION,
	REJECT_QUESTION,
	SUBMIT_NEW_QUESTION,
	GET_SUBJECT_AREAS,
	UPLOAD_FAQ_CMS_IMAGE,
	MARK_SUBMITTED_FAQ_FOR_DELETION,
	DELETE_FAQ,
	REJECT_FAQ_DELETION_SUGGESTION,
	GET_FAQ_CMS_SETTINGS,
	SAVE_FAQ_CMS_SETTINGS,
	CLEAR_FAQ_CMS_STATE,
	LOAD_QUESTION_SUGGESTIONS,
	EDIT_QUESTION
} from '../actions/types';

export default (state = { existingSubjectAreas: [], settings: {} }, action) => {
	switch (action.type) {
		case LOAD_QUESTION_SUGGESTIONS:
			return {
				...state,
				questions: formatQuestionSuggestions(action.payload.data)
			};
		case GET_QUESTIONS_TO_REVIEW:
			return {
				...state,
				questionsToReview: action.payload.data
			};
		case GET_QUESTIONS_BY_USER:
			return { ...state, questionsByUser: action.payload.data };
		case ACCEPT_QUESTION:
		case REJECT_QUESTION:
		case EDIT_QUESTION:
		case REJECT_FAQ_DELETION_SUGGESTION:
			return {
				...state,
				reviewApiCallResponse: action.payload.status
			};
		case DELETE_FAQ:
			return {
				...state,
				reviewApiCallResponse: action.payload.status,
				questionsByUser: reduceQuestions(state.questionsByUser, action.payload.deletedId)
			};
		case SUBMIT_NEW_QUESTION:
			console.log(state);
			return {
				...state,
				faqLibraryQuestionActionApiCallResponse: action.payload.status,
				questionsByUser: action.payload.questions
			};
		case MARK_SUBMITTED_FAQ_FOR_DELETION:
			return {
				...state,
				faqLibraryQuestionActionApiCallResponse: action.payload.status
			};
		case GET_SUBJECT_AREAS:
			return {
				...state,
				existingSubjectAreas: action.payload.data
			};
		case UPLOAD_FAQ_CMS_IMAGE:
			return {
				...state,
				uploadImageFromEditorApiCallResponse: action.payload.status
			};
		case GET_FAQ_CMS_SETTINGS:
			return {
				...state,
				getSettingsApiCallResponse: action.payload.status,
				settings: action.payload.data
			};
		case SAVE_FAQ_CMS_SETTINGS:
			return {
				...state,
				saveSettingsApiCallResponse: action.payload.status
			};
		case CLEAR_FAQ_CMS_STATE:
			return {};
		default:
			return state;
	}
};

// helper functions
const formatQuestionSuggestions = questions => {
	return questions?.map(hit => ({
		docId: hit.faq_id ?? hit.id,
		htmlResponse: hit.answer,
		question: hit.question,
		subjectArea: hit.subject_area,
		value: hit.question,
		label: hit.question,
		altQuestions: hit.alternative_questions
	}));
};

const reduceQuestions = (questions, delId) => {
	console.log(questions, delId);
	return questions.filter(question => question.faq_id !== delId);
};
