import { useSelector } from 'react-redux';
import { Link as Router } from 'react-router-dom';
import { Container, Alert, AlertTitle, Button } from '@mui/material';

const useCurrentRole = () => {
	return useSelector(state => state.auth?.cognitoUser?.attributes?.['custom:role']);
};

const useIsSuperAdmin = () => {
	return useSelector(state => state.auth?.cognitoUser?.attributes?.['custom:superuser']);
};

export default function RoleBasedGuard({ children, accessibleRoles, superadminRoute }) {
	const currentRole = useCurrentRole();
	const isSuperAdmin = useIsSuperAdmin();

	if ((!isSuperAdmin && superadminRoute) || !accessibleRoles.includes(currentRole)) {
		return (
			<Container>
				<Alert variant="outlined" severity="error">
					<AlertTitle>Permission Denied</AlertTitle>
					You do not have permission to access this page <br />
					<Button
						to="/"
						LinkComponent={Router}
						size="small"
						sx={{ mt: 2, ml: 'auto' }}
						variant="outlined"
					>
						Take me Home
					</Button>
				</Alert>
			</Container>
		);
	}

	return children;
}
