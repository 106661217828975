import {
	PUSH_ALL_MESSAGES,
	PUSH_NOTIFICATION,
	REMOVE_MESSAGE,
	RESET_CONFIG_STATUS,
	TOGGLE_VIDEO_PLAYER,
	UPDATE_CONFIG,
	SET_CONNECTION_STATUS,
	SET_ERROR
} from '../actions/types';

const INITIAL_STATE = { isOnline: true };

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case PUSH_NOTIFICATION: {
			return {
				...state,
				preventDuplicate: action.payload.preventDuplicate
					? action.payload.preventDuplicate
					: false,
				message: {
					type: action.payload.type,
					content: action.payload.message,
					persist: action.payload.persist,
					removePrevious: action.payload.removePrevious,
					action: action.payload.action
				}
			};
		}
		case RESET_CONFIG_STATUS: {
			return {
				...state,
				status: null
			};
		}
		case PUSH_ALL_MESSAGES: {
			return {
				...state,
				allMessages: action.payload
			};
		}
		case UPDATE_CONFIG: {
			return { ...state, status: action.payload?.status };
		}
		case REMOVE_MESSAGE: {
			return { ...state, message: undefined };
		}
		case SET_CONNECTION_STATUS: {
			return {
				...state,
				isOnline: action.payload
			};
		}
		case TOGGLE_VIDEO_PLAYER: {
			return {
				...state,
				...action.payload
			};
		}
		case SET_ERROR: {
			return {
				...state,
				...action.payload
			};
		}
		default:
			return state;
	}
}
