import {
	GET_TOOLS,
	GET_AGENTS,
	GET_AGENT_TYPES,
	GET_MODEL_TYPES,
	CREATE_AGENT,
	GET_TENANTS,
	CLEAR_STATUS,
	GET_AGENT_BY_ID,
	CLEAR_AGENT,
	UPDATE_AGENT,
	DELETE_AGENT,
	GET_CONVERSATION_LIST,
	GET_MESSAGE_HISTORY,
	GET_ALL_AGENTS,
	GET_USERS,
	APPEND_MESSAGE_HISTORY,
	APPEND_CONVERSATION_LIST
} from '../actions/types';

export default function (state = {}, action) {
	switch (action.type) {
		case GET_TOOLS: {
			return {
				...state,
				tools: action.payload
			};
		}
		case GET_AGENTS: {
			return {
				...state,
				agentList: action.payload
			};
		}
		case GET_TENANTS: {
			return {
				...state,
				tenantList: action.payload
			};
		}
		case GET_AGENT_TYPES: {
			return {
				...state,
				agentTypes: action.payload
			};
		}
		case GET_MODEL_TYPES: {
			return {
				...state,
				modelTypes: action.payload
			};
		}
		case CREATE_AGENT: {
			return {
				...state,
				agentCreateStatus: action.payload
			};
		}
		case DELETE_AGENT: {
			return {
				...state,
				agentDeleteStatus: action.payload
			};
		}
		case CLEAR_STATUS: {
			return {
				...state,
				agentCreateStatus: null,
				agentDeleteStatus: null
			};
		}
		case UPDATE_AGENT: {
			return {
				...state,
				agentCreateStatus: action.payload
			};
		}
		case GET_AGENT_BY_ID: {
			return {
				...state,
				agent: action.payload
			};
		}
		case CLEAR_AGENT: {
			return {
				...state,
				agent: null
			};
		}
		case GET_CONVERSATION_LIST: {
			return {
				...state,
				conversationList: action.payload,
				listCount: action.payload?.total_count
			};
		}
		case APPEND_CONVERSATION_LIST: {
			return {
				...state,
				conversationList: {
					...action.payload,
					histories: [...state.conversationList.histories, ...action.payload.histories]
				},
				listCount: action.payload?.total_count
			};
		}
		case GET_MESSAGE_HISTORY: {
			return {
				...state,
				conversationHistory: {
					...state.conversationHistory,
					[action.payload.id]: {
						...action.payload.messages,
						messages: action.payload.messages.messages.reverse()
					}
				}
			};
		}
		case APPEND_MESSAGE_HISTORY: {
			return {
				...state,
				conversationHistory: {
					...state.conversationHistory,
					[action.payload.id]: {
						...action.payload.messages,
						messages: [
							...action.payload.messages.messages.reverse(),
							...state.conversationHistory[action.payload.id].messages
						]
					}
				}
			};
		}
		case GET_ALL_AGENTS: {
			return {
				...state,
				allAgents: action.payload
			};
		}
		case GET_USERS: {
			return {
				...state,
				userList: action.payload
			};
		}
		default:
			return state;
	}
}
