import React from 'react';
// Styles
import { withStyles } from '@mui/styles';
import green from '@mui/material/colors/green';
import amber from '@mui/material/colors/amber';
import classnames from 'classnames';
// Material Ui core
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import IconButton from '@mui/material/IconButton';
// Material Ui icons
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';

const TRANSITION_DOWN_DURATION = 200;
const TRANSITION_DELAY = 150;

export const muiClasses = {
	root: {},
	anchorOriginTopCenter: {},
	anchorOriginBottomCenter: {},
	anchorOriginTopRight: {},
	anchorOriginBottomRight: {},
	anchorOriginTopLeft: {},
	anchorOriginBottomLeft: {}
};

export const styles = theme => ({
	...muiClasses,
	idle: {
		display: 'hide'
	},
	success: {
		backgroundColor: green[600]
	},
	error: {
		backgroundColor: theme.palette.error.dark,
		color: theme.palette.error.contrastText
	},
	info: {
		backgroundColor: theme.palette.primary.dark
	},
	warning: {
		backgroundColor: amber[700]
	},
	icon: {
		fontSize: 20
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing(1)
	},
	message: {
		alignItems: 'center'
	},
	container: {
		flex: '1'
	},
	root: {
		display: 'flex'
	}
});

const DIRECTION = {
	right: 'left',
	left: 'right',
	bottom: 'up',
	top: 'down'
};

class SnackbarItem extends React.Component {
	constructor(props) {
		super(props);
		this.ref = React.createRef();
	}

	componentDidMount() {
		const { onSetHeight, snack } = this.props;
		const height = this.ref.current && this.ref.current.clientHeight;
		onSetHeight(snack.key, height);
	}

	getTransitionStyles = (offset, anchorOrigin) => ({
		[anchorOrigin.vertical]: offset,
		WebKitTransition: `all ${TRANSITION_DOWN_DURATION}ms`,
		MozTransition: `all ${TRANSITION_DOWN_DURATION}ms`,
		msTransition: `all ${TRANSITION_DOWN_DURATION}ms`,
		OTransition: `all ${TRANSITION_DOWN_DURATION}ms`,
		transition: `all ${TRANSITION_DOWN_DURATION}ms`,
		transitionDelay: `${TRANSITION_DELAY}ms`
	});

	getTransitionDirection = anchorOrigin => {
		if (anchorOrigin.horizontal !== 'center') {
			return DIRECTION[anchorOrigin.horizontal];
		}
		return DIRECTION[anchorOrigin.vertical];
	};

	handleClose = key => (event, reason) => {
		const {
			onClose,
			snack: { onClose: singleOnClose }
		} = this.props;
		if (reason === 'clickaway') return;
		if (singleOnClose) singleOnClose(event, reason, key);
		onClose(event, reason, key);
	};

	getIcon = (variant, classes) => {
		const Icon = {
			success: CheckCircleIcon,
			warning: WarningIcon,
			error: ErrorIcon,
			info: InfoIcon,
			idle: InfoIcon
		}[variant];

		return <Icon className={classes} />;
	};

	render() {
		const { classes, autoHideDuration, offset, snack, style } = this.props;

		const { key } = snack;

		return (
			<Snackbar
				key={snack.key}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				TransitionProps={{
					direction: this.getTransitionDirection({
						vertical: 'bottom',
						horizontal: 'center'
					})
				}}
				style={{
					...style,
					...this.getTransitionStyles(offset, {
						vertical: 'bottom',
						horizontal: 'center'
					})
				}}
				autoHideDuration={autoHideDuration}
				open={snack.open}
				onClose={this.handleClose(key)}
				action={snack.message.action}
			>
				<SnackbarContent
					className={classes[snack.message.type]}
					classes={{ message: classes.container, root: classes.root }}
					aria-describedby="client-snackbar"
					message={
						<span
							id="client-snackbar"
							className={classes.message}
							style={{ display: 'flex' }}
						>
							{this.getIcon(
								snack.message.type,
								classnames(classes.icon, classes.iconVariant)
							)}
							<span>{snack.message.content}</span>
						</span>
					}
					action={[
						snack.message.action,
						<IconButton
							key="close"
							aria-label="Close"
							color="inherit"
							onClick={this.handleClose(key)}
						>
							<CloseIcon className={classes.icon} />
						</IconButton>
					]}
				/>
			</Snackbar>
		);
	}
}

export default withStyles(styles)(SnackbarItem);
