/* eslint-disable import/no-cycle */
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { Amplify } from 'aws-amplify';
import AWS from 'aws-sdk';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App';
import store from './reduxStore';
import amplifyConfig from './amplifyConfig';
import CONFIGURATION from './app_configuration';

AWS.config.region = CONFIGURATION.AWS_REGION;
Amplify.configure(amplifyConfig);

function noop() {}

console.log = noop;
console.warn = noop;
console.error = noop;

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
);
