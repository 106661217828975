export const ANALYTICS_EVENT = data => ({
	MFA_SMS: {
		success: {
			name: 'MFA',
			attributes: {
				type: 'SMS',
				status: 'success'
			}
		},
		error: {
			name: 'MFA',
			attributes: {
				type: 'SMS',
				status: 'error',
				message: data
			}
		}
	},
	MFA_TOTP: {
		success: {
			name: 'MFA',
			attributes: {
				type: 'TOTP',
				status: 'success'
			}
		},
		error: {
			name: 'MFA',
			attributes: {
				type: 'TOTP',
				status: 'error',
				message: data
			}
		}
	},
	MFA_OFF: {
		success: {
			name: 'MFA',
			attributes: {
				type: 'OFF',
				status: 'success'
			}
		},
		error: {
			name: 'MFA',
			attributes: {
				type: 'OFF',
				status: 'error',
				message: data
			}
		}
	},
	PROFILE_UPDATE: {
		name: 'Profile',
		attributes: {
			type: 'update',
			status: 'success'
		}
	},
	LOGIN: {
		success: {
			name: 'Login',
			attributes: {
				status: 'success'
			}
		},
		error: {
			name: 'Login',
			attributes: {
				status: 'error',
				message: data
			}
		}
	},
	SIGNUP: {
		success: {
			name: 'Signup',
			attributes: {
				status: 'success',
				email: data ? data.email : null
			}
		},
		error: {
			name: 'Signup',
			attributes: {
				status: 'error',
				email: data ? data.email : null,
				message: data ? data.errorMessage : null
			}
		}
	},
	CONNECTION: {
		success: {
			name: 'Connector',
			attributes: {
				type: 'enable',
				status: 'success',
				connection: data ? data.connectionId : null
			}
		},
		error: {
			name: 'Connector',
			attributes: {
				type: 'enable',
				status: 'error',
				connection: data ? data.connectionId : null,
				message: data ? data.errorMessage : null
			}
		}
	}
});

export default ANALYTICS_EVENT;
