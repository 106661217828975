import React from 'react';
// Material ui components
import Slide from '@mui/material/Slide';
// App components
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SnackbarItem from './SnackbarItem';
import { pushAllMessagesInStore } from '../../redux/actions/commonActions';

class PopUpMessages extends React.Component {
	queue = [];

	constructor(props) {
		super(props);
		this.state = {
			snacks: []
		};
	}

	componentDidUpdate(nextProps, nextState) {
		if (this.state.snacks.length !== nextState.snacks.length) {
			nextProps.pushAllMessagesInStore(nextState.snacks);
		}

		if (this.props.message) {
			this.handleEnqueueSnackbar(this.props.message);
		}
	}

	componentWillUnmount() {
		this.setState({ snacks: [] });
		this.props.pushAllMessagesInStore([]);
	}

	get offsets() {
		const { snacks } = this.state;
		return snacks.map((item, i) => {
			let index = i;
			const { view: viewOffset, snackbar: snackbarOffset } = this.props.dense
				? { view: 0, snackbar: 4 }
				: { view: 20, snackbar: 12 };
			let offset = viewOffset;
			while (snacks[index - 1]) {
				const snackHeight = snacks[index - 1].height || 48;
				offset += snackHeight + snackbarOffset;
				index -= 1;
			}
			return offset;
		});
	}

	/**
	 * Adds a new snackbar to the queue to be presented.
	 * @param {string} message - text of the notification
	 * @param {object} options - additional options for the snackbar we want to enqueue.
	 * We can pass Material-ui Snackbar props for individual customisation.
	 * @param {string} options.key
	 * @param {string} options.variant - type of the snackbar. default value is 'default'.
	 * can be: (default, success, error, warning, info)
	 * @param {bool} options.persist
	 * @param {bool} options.preventDuplicate
	 * @returns generated or user defined key referencing the new snackbar or null
	 */
	handleEnqueueSnackbar = (message, { key, preventDuplicate, ...options } = {}) => {
		if (preventDuplicate || this.props.preventDuplicate) {
			const inQueue = this.queue.findIndex(item => item.message === message) > -1;
			const inView = this.state.snacks.findIndex(item => item.message === message) > -1;
			if (inQueue || inView) {
				return null;
			}
		}

		const id = key || new Date().getTime() + Math.random();
		const snack = {
			key: id,
			message,
			...options,
			open: true
		};
		if (message.persist || message.type === 'error') {
			snack.autoHideDuration = null;
		} else {
			snack.autoHideDuration = 5000;
		}

		this.queue.push(snack);
		this.handleDisplaySnack(message.removePrevious);
		this.props.removeMessage();
		return id;
	};

	/**
	 * Display snack if there's space for it. Otherwise, immediately begin dismissing the
	 * oldest message to start showing the new one.
	 */
	handleDisplaySnack = removePrevious => {
		const { maxSnack } = this.props;
		const { snacks } = this.state;

		if (removePrevious) {
			this.setState({ snacks: [] });
		}

		if (snacks.length >= maxSnack) {
			return this.handleDismissOldest();
		}
		return this.processQueue();
	};

	/**
	 * Display items (notifications) in the queue if there's space for them.
	 */
	processQueue = () => {
		if (this.queue.length > 0) {
			const newOne = this.queue.shift();
			this.setState(({ snacks }) => ({
				snacks: [...snacks, newOne]
			}));
		}
	};

	/**
	 * Hide oldest snackbar on the screen because there exists a new one which we have to display.
	 * (ignoring the one with 'persist' flag. i.e. explicitly told by user not to get dismissed).
	 */
	handleDismissOldest = () => {
		let popped = false;
		let ignore = false;
		const persistentCount = this.state.snacks.reduce(
			(acc, current) => acc + (current.open && current.persist ? 1 : 0),
			0
		);

		if (persistentCount === this.props.maxSnack) {
			ignore = true;
		}

		this.setState(({ snacks }) => ({
			snacks: snacks
				.filter(item => item.open === true)
				.map(item => {
					if (!popped && ignore) {
						popped = true;
						if (item.onClose) item.onClose(null, 'maxsnack', item.key);
						if (this.props.onClose) this.props.onClose(null, 'maxsnack', item.key);

						return {
							...item,
							open: false
						};
					}

					return {
						...item
					};
				})
		}));
	};

	/**
	 * Hide a snackbar after its timeout.
	 * @param {object} event - The event source of the callback
	 * @param {string} reason - can be timeout or clickaway
	 * @param {number} key - id of the snackbar we want to hide
	 */
	handleCloseSnack = (event, reason, key) => {
		this.setState(({ snacks }) => ({
			snacks: snacks.map(item =>
				!key || item.key === key ? { ...item, open: false } : { ...item }
			)
		}));

		if (this.props.onClose) this.props.onClose(event, reason, key);
	};

	/**
	 * When we set open attribute of a snackbar to false (i.e. after we hide a snackbar),
	 * it leaves the screen and immediately after leaving animation is done, this method
	 * gets called. We remove the hidden snackbar from state and then display notifications
	 * waiting in the queue (if any).
	 * @param {number} key - id of the snackbar we want to remove
	 * @param {object} event - The event source of the callback
	 */
	handleExitedSnack = (event, key) => {
		const enterDelay = 150 + 200 + 40;
		this.setState(
			({ snacks }) => ({
				snacks: snacks.filter(item => item.key !== key)
			}),
			() => setTimeout(this.handleDisplaySnack, enterDelay)
		);

		if (this.props.onExited) this.props.onExited(event, key);
	};

	/**
	 * Sets height for a given snackbar
	 * @param {number} height - height of snackbar after it's been rendered
	 * @param {number} key - id of the snackbar we want to remove
	 */
	handleSetHeight = (key, height) => {
		this.setState(({ snacks }) => ({
			snacks: snacks.map(item => (item.key === key ? { ...item, height } : { ...item }))
		}));
	};

	render() {
		// const { autoHideDuration } = this.props;
		const { snacks } = this.state;

		return snacks.map((snack, index) => (
			<SnackbarItem
				key={snack.key}
				snack={snack}
				offset={this.offsets[index]}
				onClose={this.handleCloseSnack}
				onExited={this.handleExitedSnack}
				onSetHeight={this.handleSetHeight}
				autoHideDuration={snack.autoHideDuration}
			/>
		));
	}
}

PopUpMessages.defaultProps = {
	maxSnack: 3,
	dense: false,
	/* preventDuplicate: false, */
	hideIconVariant: false,
	anchorOrigin: {
		vertical: 'bottom',
		horizontal: 'left'
	},
	autoHideDuration: 5000,
	TransitionComponent: Slide
};

function mapDispatchToProps(dispatch) {
	return {
		...bindActionCreators(
			{
				pushAllMessagesInStore
			},
			dispatch
		),
		dispatch
	};
}

export default connect(null, mapDispatchToProps)(PopUpMessages);
