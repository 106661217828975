import { API, Auth } from 'aws-amplify';
import _ from 'lodash';
import CONFIGURATION from '../../app_configuration';
import {
	AUTH_USER,
	AUTH_MFA,
	AUTH_NEW_PASSWORD_REQUIRED,
	UNAUTH_USER,
	REGISTER_USER,
	REGISTER_MFA,
	REGISTER_USER_ERROR,
	FORGOT_PASSWORD,
	FORGOT_PASSWORD_CONFIRM,
	AUTH_ERROR,
	IS_LOADING,
	LOGIN_REGISTER_CONFIRMATION,
	PUSH_NOTIFICATION,
	TRIGGER_PIPELINE
} from './types';

import { setThemeSettings } from './layoutActions';

export function authError(error) {
	return {
		type: AUTH_ERROR,
		payload: error.message
	};
}

export const triggerPipeline = type => async dispatch => {
	const data = {
		body: {
			build_id: type
		},
		response: true
	};
	let response = {};
	try {
		response = await API.post(CONFIGURATION.AWS_API_NAME, '/tenant/onboarding', data);
	} catch (err) {
		response.status = 500;
	}

	dispatch({
		type: TRIGGER_PIPELINE,
		payload: response
	});
};

/**
 * This function is meant to be called on a user's first login.
 * It will create a new Dynamo user, new skill (and bot) if needed and put the new skill info into the applications'
 * state.
 * @param {object} data - data returned after successful Auth.signIn.
 * @param {function} dispatch - Used to dispatch the fetchSkill action.
 */

// Cognito - Auth.signIn()
export function login(
	username,
	password,
	history,
	location = null,
	loginWithUserPasswordAuthFlow = true
) {
	return function (dispatch) {
		if (loginWithUserPasswordAuthFlow) {
			// This alternative login flow is used when we need to migrate users in a new tenant user pool
			// More info:
			// https://docs.aws.amazon.com/cognito/latest/developerguide/cognito-user-pools-import-using-lambda.html
			Auth.configure({
				authenticationFlowType: 'USER_PASSWORD_AUTH'
			});
		} else {
			// This is the default login flow
			Auth.configure({
				authenticationFlowType: 'USER_SRP_AUTH'
			});
		}
		// console.log("actions.login(): username password:", { username, password });
		// signIn (cognito)
		Auth.signIn(username, password)
			.then(data => {
				// success -- dispatch AUTH_USER
				// console.log("INFO | actions.login():Auth.signIn() data:", data);
				// console.log(Auth.currentCredentials());

				// inspect response 'data' and check whether
				// 1. MFA confirmation is required, dispatch->AUTH_MFA
				// 2. New Password is required (change 'FORCE_CHANGE_PASSWORD'
				//    to 'CONFIRMED'), dispatch-> AUTH_NEW_PASSWORD_REQUIRED with payload
				// 3. otherwise, authenticate user, dispatch -> AUTH_USER
				if (
					data.challengeName === 'NEW_PASSWORD_REQUIRED' ||
					data.challengeName === 'FORCE_CHANGE_PASSWORD'
				) {
					dispatch({ type: AUTH_NEW_PASSWORD_REQUIRED, payload: data });
				} else if (
					data.challengeName === 'MFA_REQUIRED' ||
					data.challengeName === 'SMS_MFA' ||
					data.challengeName === 'SOFTWARE_TOKEN_MFA'
				) {
					dispatch({ type: AUTH_MFA, payload: data });
				} else {
					// dispatch AUTH_USER
					Auth.currentAuthenticatedUser({ bypassCache: true }).then(currentAuthUser => {
						dispatch({ type: AUTH_USER, payload: currentAuthUser });
						setThemeConfig(currentAuthUser, dispatch);
						let nextRoute = '/';

						// Check if this is the first time the user is signing in
						if (currentAuthUser.attributes['custom:first_login']) {
							localStorage.setItem('wizardStep', 0);
							nextRoute = '/getting-started-wizard';
						}

						history(nextRoute);
					});
				}
			})
			.catch(err => {
				console.error('actions.login():Auth.signIn() err:', err);
				if (err.code === 'UserNotConfirmedException') {
					// console.log("actions.login():Auth.signIn() UserNotConfirmedException");
					Auth.resendSignUp(username)
						.then(() => {
							// console.log("actions.resendConfirmationCode():Auth.resendSignUp() data:", data);

							dispatch({ type: LOGIN_REGISTER_CONFIRMATION });
						})
						.catch(error => {
							dispatch(authError(error));
						});
					// dispatch({ type: LOGIN_REGISTER_CONFIRMATION });
				} else if (err.code === 'PasswordResetRequiredException') {
					// Auth.forgotPassword(username).then(data => {
					//   console.log("actions.forgotPassword():Auth.forgotPassword() data:", data);
					//
					//   dispatch({ type: FORGOT_PASSWORD });
					// });
					history('/auth/reset-password');
				} else if (
					err.code === 'UserNotFoundException' &&
					err.message === 'User does not exist.' &&
					!loginWithUserPasswordAuthFlow
				) {
					// Try login flow for migrating users to a new user pool (ie new tenant)
					// NB: This will be triggered for any bad username/password combination
					return login(username, password, history, location, true)(dispatch);
				} else if (
					err.code === 'UserNotFoundException' &&
					err.message.startsWith('Exception migrating user in app client')
				) {
					// Instead of showing "Exception migrating user in app client XXXXXX" to user when user migration fails
					// (eg for wrong username/password), just show the standard message in order to not confuse the user
					err.message = 'User does not exist.';
				} else {
					err.message = 'Incorrect username and/or password';
				}

				// error -- invoke authError which dispatches AUTH_ERROR
				dispatch(authError(err));
				return null;
			});
	};
}

// Cognito - Auth.currentAuthenticatedUser()
// Cognito - Auth.userSession()
// This is a pass-through function to indicate that user has already authenticated
// and has a valid Amplify session.
export function validateUserSession() {
	return function (dispatch) {
		// console.log("actions.validateUserSession()");

		Auth.currentAuthenticatedUser({ bypassCache: true })
			.then(currentAuthUser => {
				console.log('curr user', currentAuthUser);
				// console.log("actions.validateUserSession():Auth.currentAuthenticatedUser() currentAuthUser:", currentAuthUser);
				// grab the user session
				Auth.userSession(currentAuthUser)
					.then(session => {
						// console.log("actions.validateUserSession():Auth.userSession() session:", session);
						// finally invoke isValid() method on session to check if auth tokens are valid
						// if tokens have expired, lets call "logout"
						// otherwise, dispatch AUTH_USER success action and by-pass login screen
						if (session.isValid()) {
							// fire user is authenticated
							dispatch({ type: AUTH_USER, payload: currentAuthUser });
						} else {
							// fire user is unauthenticated
							dispatch({ type: UNAUTH_USER });
						}
					})
					.catch(err => {
						console.error('actions.validateUserSession():Auth.userSession() err:', err);
						// error occured during session validation, fire user is unauthenticated
						dispatch({ type: UNAUTH_USER });
					});
			})
			.catch(err => {
				console.error(
					'actions.validateUserSession():Auth.currentAuthenticatedUser() err:',
					err
				);
				// error occured while retrieving current auth user, fire user is unauthenticated
				dispatch({ type: UNAUTH_USER });
			});
	};
}

// Cognito - Auth.completeNewPassword()
export function setNewPassword(cognitoUser, newPassword, history) {
	return function (dispatch) {
		// completeNewPassword (cognito)
		Auth.completeNewPassword(cognitoUser, newPassword)
			.then(data => {
				// console.log("actions.setNewPassword():Auth.completeNewPassword() data: ", data);

				// inspect response 'data' and check whether
				// 1. MFA confirmation is required, dispatch->AUTH_MFA
				// 2. otherwise, authenticate user, dispatch -> AUTH_USER
				if (data.challengeName === 'SMS_MFA') {
					dispatch({ type: AUTH_MFA, payload: data });
				} else {
					// dispatch AUTH_USER
					dispatch({ type: AUTH_USER, payload: data });
					setThemeConfig(data, dispatch);
					history('/');
				}
			})
			.catch(err => {
				console.error('actions.setNewPassword():Auth.completeNewPassword() err:', err);
				// error -- invoke authError which dispatches AUTH_ERROR
				dispatch(authError(err));
			});
	};
}

export function setChangePassword(oldPassword, newPassword) {
	return function (dispatch) {
		Auth.currentAuthenticatedUser()
			.then(user => {
				return Auth.changePassword(user, oldPassword, newPassword);
			})
			.then(() => {
				// console.log(data);
				dispatch({
					type: PUSH_NOTIFICATION,
					payload: {
						message: 'Password changed successfully.',
						type: 'success'
					}
				});
			})
			.catch(err => {
				console.log(err);
				let message;
				if (err.code === 'NotAuthorizedException') {
					message = 'Current password provided is not correct';
				} else {
					message = err.message;
				}
				dispatch({
					type: PUSH_NOTIFICATION,
					payload: { message, type: 'error' }
				});
			});
	};
}

// Cognito - Auth.signOut()
export function logout() {
	return function (dispatch) {
		// console.log("actions.logout(): username: ", username);

		// signOut (cognito)
		Auth.signOut()
			.then(() => {
				// console.log("actions.logout():Auth.signOut() data:", data);
				dispatch({ type: UNAUTH_USER });
			})
			.catch(err => {
				console.error('actions.logout():Auth.signOut() err:', err);
				// error -- invoke authError which dispatches AUTH_ERROR
				dispatch(authError(err));
			});
	};
}

// Cognito - Auth.confirmSignIn()
export function confirmLogin({ cognitoUser, code }, history) {
	return function (dispatch) {
		// console.log("actions.confirmLogin(): cognitoUSer, code:", {
		//   cognitoUser,
		//   code
		// });

		// confirmSignIn (cognito)
		Auth.confirmSignIn(cognitoUser, code, cognitoUser.challengeName)
			.then(() => {
				// console.log("actions.confirmLogin():Auth.confirmSignIn() data: ", data);

				Auth.currentAuthenticatedUser({ bypassCache: true }).then(currentAuthUser => {
					dispatch({ type: AUTH_USER, payload: currentAuthUser });
					setThemeConfig(currentAuthUser, dispatch);
					history('/');
				});
			})
			.catch(err => {
				console.error('actions.confirmLogin():Auth.confirmSignIn() err:', err);
				// error -- invoke authError which dispatches AUTH_ERROR
				dispatch(authError(err));
			});
	};
}

// Cognito - Auth.signUp()
export function register(email, password, history) {
	return dispatch => {
		// signUp (cognito)
		Auth.signUp({
			username: email,
			password,
			attributes: {
				email,
				'custom:role': 'admin'
			}
		})
			.then(data => {
				console.log('actions.register():Auth.signUp() data:', data);

				// MFA is required for user registration
				if (typeof data.userConfirmed !== 'undefined' && data.userConfirmed === false) {
					console.log('register mfa');
					dispatch({ type: REGISTER_MFA, payload: data });
					dispatch({ type: IS_LOADING, payload: false });
				} else {
					dispatch({ type: REGISTER_USER });

					// user registration successful, lets navigate to / route
					history('/');
				}
			})
			.catch(err => {
				console.error('actions.register():Auth.signUp() err:', err);

				// error -- invoke authError which dispatches REGISTER_USER_ERROR
				dispatch(authError(err));
			});
	};
}

// Cognito - Auth.confirmSignUp()
export function confirmRegistration({ cognitoUser, code, email, password }, history) {
	return function (dispatch) {
		const username = _.get(cognitoUser, 'user.username', _.get(cognitoUser, 'user'));

		Auth.confirmSignUp(username, code)
			.then(() => {
				dispatch(login(email, password, history));
			})
			.catch(err => {
				console.error('actions.confirmRegistration():Auth.confirmSignUp() err:', err);

				// error -- invoke authError which dispatches AUTH_ERROR
				// dispatch(authError(err));
				dispatch({
					type: REGISTER_USER_ERROR,
					payload: err.message,
					cognitoUser
				});
			});
	};
}

// Cognito - Auth.resendSignUp()
export function resendConfirmationCode(cognitoUser) {
	return function (dispatch) {
		// resendSignUp (cognito)
		Auth.resendSignUp(
			_.get(cognitoUser, 'username', _.get(cognitoUser, 'cognitoUser.user.username'))
		)
			.then(() => {
				dispatch({
					type: PUSH_NOTIFICATION,
					payload: {
						message: 'Please check your email. Confirmation code sent successfully.',
						type: 'success'
					}
				});

				// dispatch({ type: REGISTER_MFA, payload: cognitoUser });
			})
			.catch(err => {
				console.error('actions.resendConfirmationCode():Auth.resendSignUp() err:', err);

				// error -- invoke authError which dispatches AUTH_ERROR
				dispatch(authError(err));
			});
	};
}

export const removeCognitoAttribute = attribute => async () => {
	const user = await Auth.currentAuthenticatedUser();
	await Auth.deleteUserAttributes(user, [attribute]);
};

// Cognito - Auth.forgotPassword()
export function forgotPassword(username) {
	return function (dispatch) {
		Auth.forgotPassword(username)
			.then(() => {
				dispatch({ type: FORGOT_PASSWORD });
			})
			.catch(err => {
				console.error('actions.forgotPassword():Auth.forgotPassword() err:', err);

				// error -- invoke authError which dispatches AUTH_ERROR
				dispatch(authError(err));
			});
	};
}

// Cognito - Auth.forgotPasswordSubmit()
export function confirmForgotPassword(username, code, newPassword, history) {
	return function (dispatch) {
		// forgotPasswordSubmit (cognito)
		Auth.forgotPasswordSubmit(username, code, newPassword)
			.then(() => {
				// TODO - User password changed successfully, do we need to login again?
				dispatch({ type: FORGOT_PASSWORD_CONFIRM });
				history('/auth/login');
			})
			.catch(err => {
				console.error(
					'actions.confirmForgotPassword():Auth.forgotPasswordSubmit() err:',
					err
				);
				// error -- invoke authError which dispatches AUTH_ERROR
				dispatch(authError(err));
			});
	};
}

export function isLoading(state) {
	return function (dispatch) {
		dispatch({ type: IS_LOADING, payload: state });
	};
}

function setThemeConfig(cognitoUser, dispatch) {
	const config = JSON.parse(localStorage.getItem('portalConfig'));
	const mode = config?.user === cognitoUser.username ? config?.themeMode : 'dark';
	dispatch(setThemeSettings({ themeMode: mode }));
}
