import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SnackbarProvider } from 'notistack';
import useSocketEvents from '../hooks/useSocketEvents';
import Loader from './Loader';
import OnlineStatus from './OnlineStatus';
import ErrorStack from './ErrorStack';
import { MResponsive } from './@material-extend';
import ThemeConfig from '../theme';
import { validateUserSession as validateUserAction } from '../redux/actions/authActions';
import { setThemeSettings } from '../redux/actions/layoutActions';

import Router from '../routes';

function App() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { initSocket, detachSocketListeners } = useSocketEvents();
	const { initialized, authenticated, cognitoUser } = useSelector(state => state.auth);

	useEffect(() => {
		if (!authenticated) return;
		initSocket();
		setThemeConfig(cognitoUser);
		return () => {
			detachSocketListeners();
		};
	}, [authenticated]);

	useEffect(() => {
		dispatch(validateUserAction(navigate, location));
	}, [dispatch, navigate]);

	function setThemeConfig(cognitoUser) {
		const config = JSON.parse(localStorage.getItem('portalConfig'));
		const mode = config?.user === cognitoUser.username ? config?.themeMode : 'dark';
		dispatch(setThemeSettings({ themeMode: mode }));
	}

	return (
		<ThemeConfig>
			<MResponsive authenticated={authenticated}>
				<OnlineStatus />
				<ErrorStack />
				<SnackbarProvider maxSnack={3}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						{initialized ? <Router /> : <Loader />}
					</LocalizationProvider>
				</SnackbarProvider>
			</MResponsive>
		</ThemeConfig>
	);
}

export default App;
