// hooks
import { useSelector } from 'react-redux';
//
import { MAvatar } from '../../../components/@material-extend';
import createAvatar from '../../../utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
	const cognitoUser = useSelector(state => state.auth.cognitoUser);

	return (
		<MAvatar
			sx={{ p: 0.4 }}
			src={cognitoUser?.attributes?.picture}
			alt={cognitoUser?.username}
			color={
				cognitoUser?.attributes?.picture
					? 'default'
					: createAvatar(cognitoUser?.username).color
			}
			{...other}
		>
			{createAvatar(cognitoUser?.username).name}
		</MAvatar>
	);
}
